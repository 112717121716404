import {
  Select as MUISelect,
  SelectProps as MUISelectProps,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { observer } from 'mobx-react';

interface IOption {
  label: string;
  value: string | number;
}

export interface ISelectProps extends MUISelectProps {
  options: (IOption | string)[];
}

const Select = (props: ISelectProps): JSX.Element => {
  const { variant = 'outlined', error, label, options, fullWidth, ...rest } = props;

  return (
    <FormControl variant={variant} error={Boolean(error)} fullWidth={fullWidth}>
      {label && <InputLabel>{label}</InputLabel>}
      <MUISelect
        label={label}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
        {...rest}
      >
        {options.map((option) =>
          typeof option === 'object' ? (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ) : (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
      </MUISelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default observer(Select);
