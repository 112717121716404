import { Box, Paper, Typography } from '@mui/material';
import { Instance } from 'mobx-state-tree';
import React, { FC } from 'react';

import LabelValueView from 'components/LabelValueView';
import ProductModel from 'stores/models/Product';
import { getFormattedDate } from 'utils';

import Photos from '../Photos';

type TProductInfoProps = Instance<typeof ProductModel>;

const ProductInfo: FC<TProductInfoProps> = ({
  id,
  createdAt,
  updatedAt,
  name,
  vendorCode,
  route,
  description,
  imagesNames,
  properties,
  price,
  oldPrice,
  currency,
  quantity,
  merchant,
  merchantVendorCode,
  merchantPrice,
}) => (
  <>
    <LabelValueView label="id" value={id} />
    <LabelValueView label="createdAt" value={getFormattedDate(createdAt)} />
    <LabelValueView label="updatedAt" value={getFormattedDate(updatedAt)} />
    <LabelValueView label="name" value={name} />
    <LabelValueView label="vendor code" value={vendorCode} />
    <LabelValueView label="route" value={route} />
    <LabelValueView label="description" value={description} />
    <Box m={3}>
      <Paper variant="outlined">
        <Box pl={2}>
          <Typography variant="overline">Properties</Typography>
        </Box>
        <Box pl={2} pr={2}>
          {properties?.map((property) => (
            <LabelValueView key={property.id} label={property.name} value={property.value} />
          ))}
        </Box>
      </Paper>
    </Box>
    <LabelValueView label="price" value={price.toString()} />
    <LabelValueView label="old price" value={oldPrice.toString()} />
    <LabelValueView label="currency" value={currency} />
    <LabelValueView label="quantity" value={quantity.toString()} />
    <Box m={3}>
      <Paper variant="outlined">
        <Box pl={2}>
          <Typography variant="overline">Merchant</Typography>
        </Box>
        <Box pl={2} pr={2}>
          <LabelValueView label="name" value={merchant?.name} />
          <LabelValueView label="description" value={merchant?.description} />
          <LabelValueView label="merchant vendor code" value={merchantVendorCode} />
          <LabelValueView label="merchantPrice" value={merchantPrice.toString()} />
        </Box>
      </Paper>
    </Box>
    <Box m={3}>
      <Paper variant="outlined">
        <Box pl={2}>
          <Typography variant="overline">Photos</Typography>
        </Box>
        <Box pl={2} pr={2}>
          <Photos imagesNames={[...(imagesNames || [])]} />
        </Box>
      </Paper>
    </Box>
  </>
);

export default ProductInfo;
