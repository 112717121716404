export const MAIN_SERVICE = '/main';
export const CDN_SERVICE = '/cdn';
export const ARTICLE_SERVICE = '/article';
export const CONTENT_SERVICE = '/content';
export const SITES = '/sites';
export const MERCHANTS = '/merchants';
export const PRODUCT_PROPERTIES = '/product-properties';
export const PRODUCTS = '/products';
export const ASSETS = '/assets';
export const IMAGES = '/images';
export const ITEMS = '/items';
export const TAGS = '/tags';
export const CONTENTS = '/contents';
