import { Switch, Route, Redirect } from 'react-router-dom';

import { Article } from 'pages/Article';
import { Articles } from 'pages/Articles';
import Authorization from 'pages/Authorization';
import { Content } from 'pages/Content';
import { Contents } from 'pages/Contents';
import Dashboard from 'pages/Dashboard';
import Merchant from 'pages/Merchant';
import Merchants from 'pages/Merchants';
import NotFoundPage from 'pages/NotFoundPage';
import Product from 'pages/Product';
import ProductProperties from 'pages/ProductProperties';
import ProductProperty from 'pages/ProductProperty';
import Products from 'pages/Products';
import Site from 'pages/Site';
import Sites from 'pages/Sites';

import PrivateRoute from './PrivateRoute';
import { ROOT, AUTH, SITES, DASHBOARD, MERCHANTS, PRODUCT_PROPERTIES, PRODUCTS, ARTICLES, CONTENTS } from './path';

export default function Router() {
  return (
    <Switch>
      <Route exact path={ROOT}>
        <Redirect to={DASHBOARD} />
      </Route>
      <PrivateRoute path={DASHBOARD} component={Dashboard} />
      <Route path={SITES}>
        <Switch>
          <PrivateRoute exact path={SITES} component={Sites} />
          <Route path={`${SITES}/:id`}>
            <Switch>
              <PrivateRoute exact path={`${SITES}/:id`} component={Site} />
              <Route path={`${SITES}/:id${PRODUCTS}`}>
                <Switch>
                  <PrivateRoute exact path={`${SITES}/:id${PRODUCTS}`} component={Products} />
                  <PrivateRoute path={`${SITES}/:id${PRODUCTS}/:productId`} component={Product} />
                </Switch>
              </Route>
              <Route path={`${SITES}/:id${ARTICLES}`}>
                <Switch>
                  <PrivateRoute exact path={`${SITES}/:id${ARTICLES}`} component={Articles} />
                  <PrivateRoute path={`${SITES}/:id${ARTICLES}/:articleId`} component={Article} />
                </Switch>
              </Route>
              <Route path={`${SITES}/:id${CONTENTS}`}>
                <Switch>
                  <PrivateRoute exact path={`${SITES}/:id${CONTENTS}`} component={Contents} />
                  <PrivateRoute path={`${SITES}/:id${CONTENTS}/:contentId`} component={Content} />
                </Switch>
              </Route>
            </Switch>
          </Route>
        </Switch>
      </Route>
      <Route path={MERCHANTS}>
        <Switch>
          <PrivateRoute exact path={MERCHANTS} component={Merchants} />
          <PrivateRoute path={`${MERCHANTS}/:id`} component={Merchant} />
        </Switch>
      </Route>
      <Route path={PRODUCT_PROPERTIES}>
        <Switch>
          <PrivateRoute exact path={PRODUCT_PROPERTIES} component={ProductProperties} />
          <PrivateRoute path={`${PRODUCT_PROPERTIES}/:id`} component={ProductProperty} />
        </Switch>
      </Route>
      <Route path={AUTH} component={Authorization} />
      <Route component={NotFoundPage} />
    </Switch>
  );
}
