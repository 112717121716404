import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { PRODUCT_PROPERTIES } from 'router/path';
import { StoreContext } from 'stores';

interface IDeleteProductPropertyDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const DeleteProductPropertyDialog: FC<IDeleteProductPropertyDialogProps> = ({ isOpen, onClose }) => {
  const { productProperty: productPropertyStore } = useContext(StoreContext);
  const history = useHistory();

  const submit = async () => {
    const isDeleted = await productPropertyStore.deleteProductProperty(productPropertyStore.data?.id as string);

    onClose();

    if (isDeleted) history.push(PRODUCT_PROPERTIES);
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>Delete Product Property</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this product property?</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="contained" color="primary" onClick={submit}>
          Delete
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(DeleteProductPropertyDialog);
