import { Box } from '@mui/material';
import React, { FC } from 'react';

import useStyles from './styles';

interface IPageContainerProps {
  children: NonNullable<React.ReactNode>;
}

const PageContainer: FC<IPageContainerProps> = ({ children }) => {
  const styles = useStyles();

  return <Box className={styles.container}>{children}</Box>;
};

export default PageContainer;
