import { types, flow, getParent, toGenerator, Instance, applySnapshot, getSnapshot } from 'mobx-state-tree';

import { api } from 'api';
import * as urls from 'api/urls';
import { TRootStore } from 'types/store';
import { hasStringMessage } from 'utils/hasStringMessage';

import ProductProperty from './models/ProductProperty';

export type TProductProperties = Instance<typeof ProductProperty>[];

const ProductProperties = types
  .model({
    list: types.array(ProductProperty),
  })
  .actions((self) => ({
    fetch: flow(function* fetch() {
      try {
        const resp = yield* toGenerator(
          api.request<null, TProductProperties>({ url: `${urls.MAIN_SERVICE}${urls.PRODUCT_PROPERTIES}` }),
        );

        self.list.replace(resp.data.body);

        return resp;
      } catch (error: unknown) {
        const { processing } = getParent<TRootStore>(self);

        if (hasStringMessage(error)) {
          processing.setError(error.message);
        }

        return null;
      }
    }),
    fetchByName: flow(function* fetch(name: string) {
      try {
        const requestParams = new URLSearchParams();

        requestParams.append('name', name);

        const resp = yield* toGenerator(
          api.request<null, TProductProperties>({ url: `${urls.MAIN_SERVICE}${urls.PRODUCT_PROPERTIES}`, params: requestParams }),
        );

        return resp;
      } catch (error: unknown) {
        const { processing } = getParent<TRootStore>(self);

        if (hasStringMessage(error)) {
          processing.setError(error.message);
        }

        return null;
      }
    }),
    clear() {
      applySnapshot(self, {});
    },
  }))
  .views((self) => ({
    get listSnapshot() {
      return getSnapshot(self.list);
    },
    get propertyNames() {
      return [...new Set(self.list.map((property) => property.name))];
    },
    get propertiesMap(): Map<string, Instance<typeof ProductProperty>[]> {
      const map = new Map<string, Instance<typeof ProductProperty>[]>();

      self.list.forEach((property) => {
        if (!map.has(property.name)) {
          map.set(property.name, [property]);
        } else {
          map.get(property.name)?.push(property);
        }
      });

      return map;
    },
  }));

export default ProductProperties;
