import { useMutation } from '@tanstack/react-query';

import { useApi } from 'context/ApiContext';

export const useRemoveContentMutation = (onSuccess?: () => void) => {
  const { contentsApi } = useApi();

  return useMutation(
    async ({
      contentId,
      siteId,
    }: {
      contentId: string;
      siteId: string;
    }) => contentsApi.removeContent({ contentId, siteId }),
    {
      onSuccess,
    },
  );
};
