import { createApiCall } from 'api';
import * as urls from 'api/urls';
import { TAsset } from 'types/Asset';

export class AssetsApi {
  constructor(private readonly api: ReturnType<typeof createApiCall>) {}

  uploadAssets = async (assets: FormData) => this.api.request<FormData, TAsset[]>({
    url: `${urls.CDN_SERVICE}${urls.ASSETS}`,
    method: 'post',
    data: assets,
  });
}
