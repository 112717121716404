import { useMutation } from '@tanstack/react-query';

import { useApi } from 'context/ApiContext';
import { TArticleForm } from 'types/Article';

export const useCreateArticleMutation = (onSuccess?: () => void) => {
  const { articlesApi } = useApi();

  return useMutation(async ({ articleForm, siteId }: { articleForm: TArticleForm; siteId: string }) => articlesApi.createArticle({ articleForm, siteId }), {
    onSuccess,
  });
};