import { useQuery } from '@tanstack/react-query';

import { EQueries } from 'config/queryClient';
import { useApi } from 'context/ApiContext';

type TSiteQueryParams = { siteId: string; enabled: boolean };

export const useSiteQuery = ({ siteId, enabled }: TSiteQueryParams) => {
  const { sitesApi } = useApi();

  return useQuery([EQueries.CONTENTS, siteId], async () => sitesApi.getSite({ siteId }), {
    enabled,
  });
};