import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Toolbar,
  Button,
  Chip,
} from '@mui/material';
import keyBy from 'lodash/keyBy';
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { CDN_SERVICE, IMAGES } from 'api/urls';
import { ManageArticleDialog } from 'components/ManageArticleDialog';
import PageContainer from 'components/PageContainer';
import { getCDNUrl } from 'config';
import dirNames from 'consts/image';
import { useBoolean } from 'hooks/useBoolean';
import { useArticlesQuery } from 'queries/article/useArticlesQuery';
import { useTagsQuery } from 'queries/tag/useTagsQuery';
import { SITES, ARTICLES } from 'router/path';
import getFormattedDate from 'utils';

type TArticlesParams = {
  id: string;
};

export const Articles: React.FC = () => {
  const { id: siteId } = useParams<TArticlesParams>();
  const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] = useBoolean();
  const history = useHistory();

  const articlesQuery = useArticlesQuery({ siteId });

  const tagsQuery = useTagsQuery({ siteId });
  const tagsById = keyBy(tagsQuery.data?.data.body, (tag) => tag.id);

  const openArticle = (articleId: string) => {
    history.push(`${SITES}/${siteId}${ARTICLES}/${articleId}`);
  };

  const goToSite = () => {
    history.push(`${SITES}/${siteId}`);
  };

  return (
    <>
      <PageContainer>
        <Typography variant="h1">Articles</Typography>
        <Typography variant="overline">List of articles</Typography>
        <Paper elevation={8} sx={{ marginTop: (theme) => theme.spacing(3) }}>
          <Toolbar
            variant="dense"
            sx={{
              justifyContent: 'space-between',
              backgroundColor: (theme) => theme.palette.grey[100],
              borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`,
            }}
          >
            <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={goToSite}>
              Back to site
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={openCreateDialog}
            >
              Add new article
            </Button>
          </Toolbar>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Img</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>CreatedAt</TableCell>
                  <TableCell>UpdatedAt</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Route</TableCell>
                  <TableCell>Tags</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {articlesQuery.data?.data.body.map((article) => (
                  <TableRow key={article.id} sx={{ cursor: 'pointer' }} onClick={() => { openArticle(article.id); }}>
                    <TableCell>
                      {article.imagesNames?.[0] && (
                        <img width={100} src={`${getCDNUrl()}${CDN_SERVICE}${IMAGES}/${dirNames[0]}/${(article.imagesNames[0])}`} alt="" />
                      )}
                    </TableCell>
                    <TableCell>{article.id}</TableCell>
                    <TableCell>{getFormattedDate(article.createdAt)}</TableCell>
                    <TableCell>{getFormattedDate(article.updatedAt)}</TableCell>
                    <TableCell>{article.title}</TableCell>
                    <TableCell>{article.route}</TableCell>
                    <TableCell>
                      {(article.tagIds ?? []).map((tagId) => (
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        <Chip key={tagId} label={tagsById[tagId]?.name} sx={{ marginRight: 1 }} />
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </PageContainer>

      <ManageArticleDialog isNew isOpen={isCreateDialogOpen} onClose={closeCreateDialog} />
    </>
  );
};
