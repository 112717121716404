import { useMutation } from '@tanstack/react-query';

import { useApi } from 'context/ApiContext';

export const useRemoveArticleMutation = (onSuccess?: () => void) => {
  const { articlesApi } = useApi();

  return useMutation(
    async ({
      articleId,
      siteId,
    }: {
      articleId: string;
      siteId: string;
    }) => articlesApi.removeArticle({ articleId, siteId }),
    {
      onSuccess,
    },
  );
};
