import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { SITES } from 'router/path';
import { StoreContext } from 'stores';

interface IDeleteProductDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

interface IDeleteProductDialogParams {
  id: string;
}

const DeleteProductDialog: FC<IDeleteProductDialogProps> = ({ isOpen, onClose }) => {
  const { product } = useContext(StoreContext);
  const history = useHistory();
  const { id } = useParams<IDeleteProductDialogParams>();

  const submit = async () => {
    const isDeleted = await product.deleteProduct(id, product.data?.id as string);

    onClose();

    if (isDeleted) history.push(`${SITES}/${id}`);
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>Delete Product</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this product?</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="contained" color="primary" onClick={submit}>
          Delete
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(DeleteProductDialog);
