import { AUTH0_AUDIENCE } from 'config';
import { ROLE_OWNER } from 'consts/admin';
import { TAuth0User } from 'stores/models/Auth0User';

export const isSuperAdmin = (admin: TAuth0User | undefined): boolean => {
  if (!admin) return false;

  const roles = admin[`${AUTH0_AUDIENCE}/claims/roles`] as string[] | undefined ?? [];

  return roles.some((role: string): boolean => role === ROLE_OWNER);
};

export default isSuperAdmin;
