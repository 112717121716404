import { types, Instance } from 'mobx-state-tree';

let key = 0;

const Options = types.model({
  variant: types.optional(types.string, 'error'),
});

const SuccessAction = types.model({
  key: types.number,
  message: types.optional(types.string, 'Action completed successfully'),
  options: types.optional(Options, { variant: 'success' }),
});

const ServerError = types.model({
  key: types.number,
  message: types.optional(types.string, 'Unknown error'),
  options: types.optional(Options, { variant: 'error' }),
});

const Processing = types
  .model({
    successActionList: types.array(SuccessAction),
    errorList: types.array(ServerError),
    displayedErrors: types.array(types.number),
    displayedActions: types.array(types.number),
    actionsInProgress: types.optional(types.number, 0),
  })
  .actions((self) => ({
    setError(message: string) {
      self.errorList.push({ key, message });
      key += 1;

      return this;
    },
    setDisplayedErrors(k: number) {
      self.displayedErrors.push(k);

      return this;
    },
    removeError(val: Instance<typeof ServerError>) {
      self.errorList.remove(val);

      return this;
    },
    increaseInProgress() {
      self.actionsInProgress += 1;

      return this;
    },
    decreaseInProgress() {
      self.actionsInProgress = self.actionsInProgress > 0 ? self.actionsInProgress - 1 : 0;

      return this;
    },
    addSuccessfulAction(message: string) {
      self.successActionList.push({ key, message });
      key += 1;

      return this;
    },
    removeAction(val: Instance<typeof SuccessAction>) {
      self.successActionList.remove(val);

      return this;
    },
    setDisplayedAction(k: number) {
      self.displayedActions.push(k);

      return this;
    },
  }))
  .views((self) => ({
    get isLoading() {
      return self.actionsInProgress > 0;
    },
  }));

export default Processing;
