import { createApiCall } from 'api';
import * as urls from 'api/urls';
import { TArticleForm, TArticle } from 'types/Article';

export class ArticlesApi {
  constructor(private readonly api: ReturnType<typeof createApiCall>) {}

  createArticle = async ({
    articleForm,
    siteId,
  }: {
    articleForm: TArticleForm;
    siteId: string;
  }) =>
    this.api.request<Omit<TArticleForm, 'tagIds'> & { siteId: string; tagIds: string[] }, TArticle>({
      url: `${urls.ARTICLE_SERVICE}${urls.SITES}/${siteId}${urls.ITEMS}`,
      method: 'post',
      data: { ...articleForm, tagIds: articleForm.tagIds.map(tag => tag.id), siteId },
    });

  editArticle = async ({
    articleId,
    articleForm,
    siteId,
  }: {
    articleId: string;
    articleForm: TArticleForm;
    siteId: string;
  }) =>
    this.api.request<Omit<TArticleForm, 'tagIds'> & { siteId: string; tagIds: string[] }, TArticle>({
      url: `${urls.ARTICLE_SERVICE}${urls.SITES}/${siteId}${urls.ITEMS}/${articleId}`,
      method: 'put',
      data: { ...articleForm, tagIds: articleForm.tagIds.map(tag => tag.id), siteId },
    });

  getArticle = async ({
    articleId,
    siteId,
  }: {
    articleId: string;
    siteId: string;
  }) =>
    this.api.request<null, TArticle>({
      url: `${urls.ARTICLE_SERVICE}${urls.SITES}/${siteId}${urls.ITEMS}/${articleId}`,
    });

  getArticles = async ({
    siteId,
  }: {
    siteId: string;
  }) =>
    this.api.request<null, TArticle[]>({
      url: `${urls.ARTICLE_SERVICE}${urls.SITES}/${siteId}${urls.ITEMS}`,
    });

  removeArticle = async ({
    articleId,
    siteId,
  }: {
    articleId: string;
    siteId: string;
  }) =>
    this.api.request<TArticleForm & { siteId: string }, TArticle>({
      url: `${urls.ARTICLE_SERVICE}${urls.SITES}/${siteId}${urls.ITEMS}/${articleId}`,
      method: 'delete',
    });
}
