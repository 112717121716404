import { types, flow, getParent, toGenerator, Instance, applySnapshot } from 'mobx-state-tree';

import { api } from 'api';
import * as urls from 'api/urls';
import { TRootStore } from 'types/store';
import { hasStringMessage } from 'utils/hasStringMessage';

import SiteModel from './models/Site';

const Site = types
  .model({
    data: types.maybe(SiteModel),
  })
  .actions((self) => ({
    fetch: flow(function* fetch(siteId: string) {
      try {
        const resp = yield* toGenerator(
          api.request<null, Instance<typeof SiteModel>>({ url: `${urls.MAIN_SERVICE}${urls.SITES}/${siteId}` }),
        );

        self.data = resp.data.body;

        return resp;
      } catch (error: unknown) {
        const { processing } = getParent<TRootStore>(self);

        if (hasStringMessage(error)) {
          processing.setError(error.message);
        }

        return null;
      }
    }),
    createSite: flow(function* createSite(data: Instance<typeof SiteModel>) {
      try {
        const resp = yield* toGenerator(
          api.request<Instance<typeof SiteModel>, Instance<typeof SiteModel>>({
            url: `${urls.MAIN_SERVICE}${urls.SITES}`,
            method: 'post',
            data,
          }),
        );

        return resp;
      } catch (error: unknown) {
        const { processing } = getParent<TRootStore>(self);

        if (hasStringMessage(error)) {
          processing.setError(error.message);
        }

        return null;
      }
    }),
    editSite: flow(function* editSite(siteId: string, data: Instance<typeof SiteModel>) {
      try {
        const resp = yield* toGenerator(
          api.request<Instance<typeof SiteModel>, Instance<typeof SiteModel>>({
            url: `${urls.MAIN_SERVICE}${urls.SITES}/${siteId}`,
            method: 'put',
            data,
          }),
        );

        self.data = resp.data.body;

        return resp;
      } catch (error: unknown) {
        const { processing } = getParent<TRootStore>(self);

        if (hasStringMessage(error)) {
          processing.setError(error.message);
        }

        return null;
      }
    }),
    deleteSite: flow(function* deleteSite(siteId: string) {
      try {
        yield* toGenerator(
          api.request<null, null>({
            url: `${urls.MAIN_SERVICE}${urls.SITES}/${siteId}`,
            method: 'delete',
          }),
        );
        applySnapshot(self, {});

        return true;
      } catch (error: unknown) {
        const { processing } = getParent<TRootStore>(self);

        if (hasStringMessage(error)) {
          processing.setError(error.message);
        }

        return null;
      }
    }),
    clear() {
      applySnapshot(self, {});
    },
  }));

export default Site;
