import { ImageList, ImageListItem } from '@mui/material';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { CDN_SERVICE, IMAGES } from 'api/urls';
import { getCDNUrl } from 'config';
import { dirNames } from 'consts/image';

import useStyles from './styles';

interface IPhotosProps {
  imagesNames: string[];
}

const Photos: FC<IPhotosProps> = ({ imagesNames }) => {
  const styles = useStyles();

  return (
    <ImageList className={styles.imageList} cols={3}>
      {imagesNames.map((imageName) => (
        <ImageListItem key={imageName}>
          <img src={`${getCDNUrl()}${CDN_SERVICE}${IMAGES}/${dirNames[2]}/${imageName}`} alt="" />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default observer(Photos);
