import { useAuth0 } from '@auth0/auth0-react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Box, MenuItem, Menu as MUIMenu, Button, Grid } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react';
import React, { FC, MouseEvent } from 'react';

import { env } from 'config';

import packageJson from '../../../../../package.json';

import useStyles from './styles';

type TAnchorEl = null | HTMLElement;

interface IUserSectionState {
  anchorEl: TAnchorEl;
  setAnchorEl: (el: TAnchorEl) => void;
}

const UserSection: FC = () => {
  const { user, logout } = useAuth0();
  const state = useLocalObservable<IUserSectionState>(() => ({
    anchorEl: null,

    setAnchorEl(el) {
      this.anchorEl = el;
    },
  }));
  const open = Boolean(state.anchorEl);
  const styles = useStyles();

  const openUserMenu = (event: MouseEvent<HTMLElement>) => {
    state.setAnchorEl(event.currentTarget);
  };

  const closeUserMenu = () => {
    state.setAnchorEl(null);
  };

  const logoutUser = () => {
    switch (env) {
      case 'prod':
        logout({ returnTo: 'https://office.ds-portal.ru' });
        break;
      default:
        logout({ returnTo: 'http://localhost:3000' });
    }
  };

  return (
    <Grid container item xs={2} alignItems="center" justifyContent="flex-end">
      <Grid item>
        <Button color="secondary" startIcon={<AccountCircle />} onClick={openUserMenu}>
          {user?.name}
        </Button>
        <MUIMenu
          anchorEl={state.anchorEl}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={open}
          onClose={closeUserMenu}
        >
          <MenuItem onClick={logoutUser}>Logout</MenuItem>
        </MUIMenu>
      </Grid>
      <Grid item className={styles.versionWrapper}>
        <Box className={styles.version}>{`v ${packageJson.version}`}</Box>
      </Grid>
    </Grid>
  );
};

export default observer(UserSection);
