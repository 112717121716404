import { useMutation } from '@tanstack/react-query';

import { useApi } from 'context/ApiContext';

export const useUploadAssetsMutation = (onSuccess?: () => void) => {
  const { assetsApi } = useApi();

  return useMutation(async (assets: FormData) => assetsApi.uploadAssets(assets), {
    onSuccess,
  });
};