import { createApiCall } from 'api';
import * as urls from 'api/urls';
import { TTag, TTagForm } from 'types/Tag';

export class TagsApi {
  constructor(private readonly api: ReturnType<typeof createApiCall>) {}

  createTag = async ({
    tagForm,
    siteId,
  }: {
    tagForm: TTagForm;
    siteId: string;
  }) =>
    this.api.request<TTagForm & { siteId: string }, TTag>({
      url: `${urls.ARTICLE_SERVICE}${urls.SITES}/${siteId}${urls.TAGS}`,
      method: 'post',
      data: { ...tagForm, siteId },
    });

  getTags = async ({
    siteId,
  }: {
    siteId: string;
  }) =>
    this.api.request<null, TTag[]>({
      url: `${urls.ARTICLE_SERVICE}${urls.SITES}/${siteId}${urls.TAGS}`,
    });
}
