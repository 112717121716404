/* eslint-disable @typescript-eslint/naming-convention */
import deepOrange from '@mui/material/colors/deepOrange';
import grey from '@mui/material/colors/grey';
import { createTheme, Theme } from '@mui/material/styles';

import { BLACK_COLOR, WHITE_COLOR, DARK_GREY_COLOR, DARK_DEEP_ORANGE_COLOR } from './const';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  palette: {
    common: {
      black: BLACK_COLOR,
      white: WHITE_COLOR,
    },
    primary: {
      light: deepOrange[500],
      main: deepOrange[900],
      dark: DARK_DEEP_ORANGE_COLOR,
      contrastText: WHITE_COLOR,
    },
    secondary: {
      light: WHITE_COLOR,
      main: grey[50],
      dark: DARK_GREY_COLOR,
      contrastText: BLACK_COLOR,
    },
  },
  typography: {
    h1: {
      fontSize: 22,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    h2: {
      fontSize: 20,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    h3: {
      fontSize: 18,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    h4: {
      fontSize: 16,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    h5: {
      fontSize: 14,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    h6: {
      fontSize: 12,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        pre {
          color: white;
          border: ${grey[600]} 1px solid;
          border-radius: 3px;
          margin-right: 16px;
          padding: 16px;
          background-color: black;
          overflow: hidden;
        }
      `,
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root:last-child': {
            '& .MuiTableCell-root': {
              borderBottom: 'none',
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          padding: '8px 16px',
          textTransform: 'uppercase',
          fontSize: 12,
          fontWeight: 'bold',
          color: grey[500],
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '& .MuiBox-root': {
            marginBottom: '8px',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 24px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            position: 'absolute',
            top: -1,
          },
        },
      },
    },
  },
});

export default theme;
