import { LinearProgress } from '@mui/material';
import { observer } from 'mobx-react';
import React, { FC, useContext } from 'react';

import { StoreContext } from 'stores';

import useStyles from './styles';

const LinearLoader: FC = () => {
  const { processing } = useContext(StoreContext);
  const styles = useStyles();

  return processing.isLoading ? <LinearProgress classes={{ root: styles.progress }} /> : null;
};

export default observer(LinearLoader);
