import { types } from 'mobx-state-tree';

const Merchant = types.model({
  id: types.string,
  createdAt: types.string,
  updatedAt: types.string,
  name: types.string,
  description: types.string,
});

export default Merchant;
