import { TextField as MUITextField, SelectProps as MUISelectProps } from '@mui/material';
import { observer } from 'mobx-react';

import { mapFieldToProps } from 'utils/mapFieldToProps';

interface ISelectProps extends MUISelectProps {
  field: any;
}

const TextField = (props: ISelectProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { field, variant = 'outlined', ...rest } = props;

  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
    <MUITextField {...mapFieldToProps(field)} error={Boolean(field.error)} helperText={field.error} variant={variant} {...rest} />
  );
};

export default observer(TextField);
