import { useQuery } from '@tanstack/react-query';

import { EQueries } from 'config/queryClient';
import { useApi } from 'context/ApiContext';

type TArticleQueryParams = { articleId: string; siteId: string; enabled: boolean };

export const useArticleQuery = ({ articleId, siteId, enabled }: TArticleQueryParams) => {
  const { articlesApi } = useApi();

  return useQuery([EQueries.ARTICLES, articleId], async () => articlesApi.getArticle({ articleId, siteId }), {
    enabled,
  });
};