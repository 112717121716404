import { useQuery } from '@tanstack/react-query';

import { EQueries } from 'config/queryClient';
import { useApi } from 'context/ApiContext';

type TContentQueryParams = { contentId: string; siteId: string; enabled: boolean };

export const useContentQuery = ({ contentId, siteId, enabled }: TContentQueryParams) => {
  const { contentsApi } = useApi();

  return useQuery([EQueries.CONTENTS, contentId], async () => contentsApi.getContent({ contentId, siteId }), {
    enabled,
  });
};