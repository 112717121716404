import { useState } from 'react';

export const useBoolean = (defaultValue = false): [value: boolean, setTrue: () => void, setFalse: () => void, toggleValue: () => void] => {
  const [value, setValue] = useState<boolean>(defaultValue);

  const setTrue = () => {
    setValue(true);
  };

  const setFalse = () => {
    setValue(false);
  };

  const toggleValue = () => {
    setValue(!value);
  };

  return [value, setTrue, setFalse, toggleValue];
};
