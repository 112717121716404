import { Auth0Provider } from '@auth0/auth0-react';
import '@fontsource/roboto';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import { createRoot } from 'react-dom/client';

import Notifier from 'components/Notifier';
import { queryClient } from 'config/queryClient';
import { ApiProvider } from 'context/ApiContext';
import { StoreContext, StoreModel } from 'stores';
import theme from 'theme';

import App from './App';
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_AUDIENCE } from './config';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    audience={`${AUTH0_AUDIENCE}/api`}
    redirectUri={window.location.origin}
  >
    <QueryClientProvider client={queryClient}>
      <ApiProvider>
        <StoreContext.Provider value={StoreModel}>
          <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
              <SnackbarProvider autoHideDuration={3000} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
                <CssBaseline />
                <GlobalStyles
                  styles={{
                    td: {
                      verticalAlign: 'top',
                      padding: 8,
                    },
                  }}
                />
                <Notifier />
                <App />
              </SnackbarProvider>
            </StyledEngineProvider>
          </ThemeProvider>
        </StoreContext.Provider>
      </ApiProvider>
    </QueryClientProvider>
  </Auth0Provider>,
);
