import { TableRow, TableCell } from '@mui/material';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { SITES, PRODUCTS } from 'router/path';
import Product from 'stores/models/Product';

import useStyles from './styles';

interface IProductTableRowProps {
  product: Instance<typeof Product>;
  siteId: string;
}

const ProductTableRow: FC<IProductTableRowProps> = ({
  product: { id, name, vendorCode, route, quantity, price, merchantPrice, currency },
  siteId,
}) => {
  const history = useHistory();
  const styles = useStyles();

  const openProduct = () => {
    history.push(`${SITES}/${siteId}${PRODUCTS}/${id}`);
  };

  return (
    <TableRow key={id} className={styles.row} onClick={openProduct}>
      <TableCell>{name}</TableCell>
      <TableCell>{vendorCode}</TableCell>
      <TableCell>{route}</TableCell>
      <TableCell>{quantity}</TableCell>
      <TableCell>{price}</TableCell>
      <TableCell>{merchantPrice}</TableCell>
      <TableCell>{currency}</TableCell>
    </TableRow>
  );
};

export default observer(ProductTableRow);
