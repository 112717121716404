import makeStyles from '@mui/styles/makeStyles';

import { PAGE_CONTAINER_PADDING } from 'theme/const';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: PAGE_CONTAINER_PADDING,
    paddingLeft: 24,
    paddingRight: 24,
  },
}));

export default useStyles;
