import { types, flow, getParent, toGenerator, Instance, applySnapshot } from 'mobx-state-tree';

import { api } from 'api';
import * as urls from 'api/urls';
import { TRootStore } from 'types/store';
import { hasStringMessage } from 'utils/hasStringMessage';

import Product from './models/Product';

type TProducts = Instance<typeof Product>[];

const Products = types
  .model({
    list: types.array(Product),
  })
  .actions((self) => ({
    fetch: flow(function* fetch(siteId: string) {
      try {
        const resp = yield* toGenerator(
          api.request<null, TProducts>({ url: `${urls.MAIN_SERVICE}${urls.SITES}/${siteId}${urls.PRODUCTS}` }),
        );

        self.list.replace(resp.data.body);

        return resp;
      } catch (error: unknown) {
        const { processing } = getParent<TRootStore>(self);

        if (hasStringMessage(error)) {
          processing.setError(error.message);
        }

        return null;
      }
    }),
    clear() {
      applySnapshot(self, {});
    },
  }));

export default Products;
