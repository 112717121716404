import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  Toolbar,
  Button,
} from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react';
import React, { useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import ManageProductDialog from 'components/ManageProductDialog';
import PageContainer from 'components/PageContainer';
import { SITES } from 'router/path';
import { StoreContext } from 'stores';

import ProductTableHead from './components/ProductTableHead';
import ProductTableRow from './components/ProductTableRow';

interface IProductsState {
  isCreateDialogOpen: boolean;

  openCreateDialog: () => void;
  closeCreateDialog: () => void;
}

type TProductsParams = {
  id: string;
};

const Products: React.FC = () => {
  const { site, products, productProperties, merchants } = useContext(StoreContext);
  const { id: siteId } = useParams<TProductsParams>();
  const state = useLocalObservable<IProductsState>(() => ({
    isCreateDialogOpen: false,

    openCreateDialog: () => {
      state.isCreateDialogOpen = true;
    },
    closeCreateDialog: () => {
      state.isCreateDialogOpen = false;
    },
  }));
  const history = useHistory();

  useEffect(() => {
    const fetch = () => {
      void site.fetch(siteId);
      void products.fetch(siteId);
      void productProperties.fetch();
      void merchants.fetch();
    };

    fetch();

    return () => {
      site.clear();
      products.clear();
      productProperties.clear();
      merchants.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToSite = () => {
    history.push(`${SITES}/${siteId}`);
  };

  return (
    <PageContainer>
      <Typography variant="h1">Products</Typography>
      <Typography variant="overline">List of products</Typography>
      <Paper elevation={8} sx={{ marginTop: (theme) => theme.spacing(3) }}>
        <Toolbar
          variant="dense"
          sx={{
            justifyContent: 'space-between',
            backgroundColor: (theme) => theme.palette.grey[100],
            borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`,
          }}
        >
          <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={goToSite}>
            Back to site
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={state.openCreateDialog}
          >
            Add new product
          </Button>
        </Toolbar>
        <TableContainer>
          <Table>
            <ProductTableHead />
            <TableBody>
              {products.list.map((product) => (
                <ProductTableRow {...{ key: product.id, product, siteId }} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <ManageProductDialog
        isOpen={state.isCreateDialogOpen}
        onClose={state.closeCreateDialog}
      />
    </PageContainer>
  );
};

export default observer(Products);
