import { Instance } from 'mobx-state-tree';
import React, { FC } from 'react';

import LabelValueView from 'components/LabelValueView';
import MerchantModel from 'stores/models/Merchant';
import { getFormattedDate } from 'utils';

type TMerchantInfoProps = Instance<typeof MerchantModel>;

const MerchantInfo: FC<TMerchantInfoProps> = ({ id, createdAt, updatedAt, name, description }) => (
  <>
    <LabelValueView label="id" value={id} />
    <LabelValueView label="createdAt" value={getFormattedDate(createdAt)} />
    <LabelValueView label="updatedAt" value={getFormattedDate(updatedAt)} />
    <LabelValueView label="name" value={name} />
    <LabelValueView label="description" value={description} />
  </>
);

export default MerchantInfo;
