import { TextField, Autocomplete, CircularProgress, createFilterOptions } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';

import { EQueries } from 'config/queryClient';
import { useCreateTagMutation } from 'mutations/tag/useCreateTagMutation';
import { useTagsQuery } from 'queries/tag/useTagsQuery';
import { TTag } from 'types/Tag';

type TTagOption = Partial<TTag> & {
  inputValue?: string;
};

type TTagsAutocompleteProps = {
  siteId: string;
  value: TTagOption[] | string[];
  onChange: (tags: TTagOption[]) => void;
};

const filter = createFilterOptions<TTagOption>();

export const TagsAutocomplete: React.FC<TTagsAutocompleteProps> = React.forwardRef(({ siteId, value, onChange }, ref) => {
  const queryClient = useQueryClient();

  const { data, isFetching } = useTagsQuery({ siteId });

  const createTagMutation = useCreateTagMutation(() => {
    void queryClient.invalidateQueries([EQueries.TAGS]);
  });

  return (
    <Autocomplete
      ref={ref}
      freeSolo
      multiple
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      value={value}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      options={(data?.data.body ?? []) as TTagOption[]}
      loading={isFetching || createTagMutation.isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Tags"
          disabled={isFetching || createTagMutation.isLoading}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isFetching || createTagMutation.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.name);

        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            name: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }

        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }

        // Regular option
        return option.name ?? '';
      }}
      onChange={async (event, newValue) => {
        const nonExistedTag = newValue.find(tag => !!(tag as TTagOption).inputValue);

        // Existed tag was chosen
        if (!nonExistedTag) {
          onChange([...new Map((newValue as TTagOption[]).map((tag) => [tag.name, tag])).values()]);

          return;
        }

        if (!(nonExistedTag as TTagOption).inputValue) return;

        const resp = await createTagMutation.mutateAsync({ siteId, tagForm: { name: (nonExistedTag as TTagOption).inputValue ?? '' } });
        const filteredTags = (newValue as TTagOption[]).filter(tag => !tag.inputValue);

        onChange([...filteredTags, resp.data.body]);
      }}
    />
  );
});