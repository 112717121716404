import { Instance } from 'mobx-state-tree';
import { FC } from 'react';

import LabelValueView from 'components/LabelValueView';
import SiteModel from 'stores/models/Site';
import { getFormattedDate } from 'utils';

type TSiteInfoProps = Instance<typeof SiteModel>;

const SiteInfo: FC<TSiteInfoProps> = ({ id, createdAt, url, description, allowedProperties }) => (
  <>
    <LabelValueView label="id" value={id} />
    <LabelValueView label="createdAt" value={getFormattedDate(createdAt)} />
    <LabelValueView label="url" value={url} />
    <LabelValueView label="description" value={description} />
    <LabelValueView label="allowed properties" value={allowedProperties?.join(', ')} />
  </>
);

export default SiteInfo;
