import { CircularProgress, Box, Typography } from '@mui/material';
import React from 'react';

export const Loader: React.FC = () => (
  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="200px" width="100%">
    <CircularProgress />
    <Box display="flex" pt={4}>
      <Typography sx={{ color: (theme) => theme.palette.grey[900] }}>
          Loading...
      </Typography>
    </Box>
  </Box>
);
