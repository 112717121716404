import { Instance } from 'mobx-state-tree';
import { FC } from 'react';

import LabelValueView from 'components/LabelValueView';
import ProductPropertyModel from 'stores/models/ProductProperty';
import { getFormattedDate } from 'utils';

type TProductPropertyInfoProps = Instance<typeof ProductPropertyModel>;

const ProductPropertyInfo: FC<TProductPropertyInfoProps> = ({ id, createdAt, updatedAt, name, value, code }) => (
  <>
    <LabelValueView label="id" value={id} />
    <LabelValueView label="createdAt" value={getFormattedDate(createdAt)} />
    <LabelValueView label="updatedAt" value={getFormattedDate(updatedAt)} />
    <LabelValueView label="name" value={name} />
    <LabelValueView label="value" value={value} />
    <LabelValueView label="code" value={code} />
  </>
);

export default ProductPropertyInfo;
