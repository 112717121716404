import { Typography, Grid } from '@mui/material';
import { FC } from 'react';

import useStyles from './styles';

interface ILabelValueViewProps {
  label: string;
  value: string | undefined;
}

const LabelValueView: FC<ILabelValueViewProps> = ({ label, value }) => {
  const styles = useStyles();

  return (
    <Grid container>
      <Grid item xs={3}>
        <Typography className={styles.label}>{label}</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography className={styles.value}>{value || '-'}</Typography>
      </Grid>
    </Grid>
  );
};

export default LabelValueView;
