import { useMutation } from '@tanstack/react-query';

import { useApi } from 'context/ApiContext';
import { TTagForm } from 'types/Tag';

export const useCreateTagMutation = (onSuccess?: () => void) => {
  const { tagsApi } = useApi();

  return useMutation(async ({ tagForm, siteId }: { tagForm: TTagForm; siteId: string }) => tagsApi.createTag({ tagForm, siteId }), {
    onSuccess,
  });
};