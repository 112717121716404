import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { EQueries } from 'config/queryClient';
import { useRemoveContentMutation } from 'mutations/content/useRemoveContentMutation';
import { SITES, CONTENTS } from 'router/path';

interface IRemoveContentDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

interface IRemoveContentDialogParams {
  id: string;
  contentId: string;
}

export const RemoveContentDialog: FC<IRemoveContentDialogProps> = ({ isOpen, onClose }) => {
  const history = useHistory();
  const { id: siteId, contentId } = useParams<IRemoveContentDialogParams>();
  const queryClient = useQueryClient();

  const removeContentMutation = useRemoveContentMutation(() => {
    void queryClient.invalidateQueries([EQueries.CONTENTS], { exact: true });
  });

  const remove = async () => {
    await removeContentMutation.mutateAsync({ siteId, contentId });
    history.push(`${SITES}/${siteId}${CONTENTS}`);
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>Delete Content</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this content?</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="contained" color="primary" onClick={remove}>
          Delete
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
