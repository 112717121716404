import { withAuthenticationRequired } from '@auth0/auth0-react';
import React, { FC } from 'react';
import { Route } from 'react-router-dom';

interface IPrivateRouteProps {
  component: React.FC;
  path: string;
  exact?: boolean;
}

const PrivateRoute: FC<IPrivateRouteProps> = ({ component: Component, ...args }) => (
  <Route component={withAuthenticationRequired(Component)} {...args} />
);

export default PrivateRoute;
