import { Typography, Paper, Toolbar, Button } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react';
import React, { FC, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import PageContainer from 'components/PageContainer';
import { PRODUCT_PROPERTIES } from 'router/path';
import { StoreContext } from 'stores';

import DeleteProductPropertyDialog from './components/DeleteProductPropertyDialog';
import EditProductPropertyDialog from './components/EditProductPropertyDialog';
import ProductPropertyInfo from './components/ProductPropertyInfo';
import useStyles from './styles';

interface IProductPropertyParams {
  id: string;
}

interface IProductPropertyState {
  isEditDialogOpen: boolean;
  isDeleteDialogOpen: boolean;

  openEditDialog: () => void;
  closeEditDialog: () => void;
  openDeleteDialog: () => void;
  closeDeleteDialog: () => void;
}

const ProductProperty: FC = () => {
  const { id } = useParams<IProductPropertyParams>();
  const { productProperty: productPropertyStore } = useContext(StoreContext);
  const state = useLocalObservable<IProductPropertyState>(() => ({
    isEditDialogOpen: false,
    isDeleteDialogOpen: false,

    openEditDialog: () => {
      state.isEditDialogOpen = true;
    },
    closeEditDialog: () => {
      state.isEditDialogOpen = false;
    },
    openDeleteDialog: () => {
      state.isDeleteDialogOpen = true;
    },
    closeDeleteDialog: () => {
      state.isDeleteDialogOpen = false;
    },
  }));
  const history = useHistory();
  const styles = useStyles();

  useEffect(() => {
    const fetch = async () => {
      const prop = await productPropertyStore.fetch(id);

      if (!prop) history.push(PRODUCT_PROPERTIES);
    };

    void fetch();

    return () => { productPropertyStore.clear(); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer>
      <Typography variant="h1">Product Property</Typography>
      <Typography variant="overline">{productPropertyStore.data?.name}</Typography>
      <Paper elevation={8} className={styles.paper}>
        <>
          <Toolbar variant="dense" className={styles.toolbar}>
            <Button variant="contained" color="primary" onClick={state.openEditDialog}>
              Edit
            </Button>
            <Button variant="outlined" onClick={state.openDeleteDialog}>
              Delete
            </Button>
          </Toolbar>
          {productPropertyStore.data && <ProductPropertyInfo {...productPropertyStore.data} />}
        </>
      </Paper>

      <EditProductPropertyDialog isOpen={state.isEditDialogOpen} onClose={state.closeEditDialog} />
      <DeleteProductPropertyDialog isOpen={state.isDeleteDialogOpen} onClose={state.closeDeleteDialog} />
    </PageContainer>
  );
};

export default observer(ProductProperty);
