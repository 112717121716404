import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { MERCHANTS } from 'router/path';
import { StoreContext } from 'stores';

interface IDeleteMerchantDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const DeleteMerchantDialog: FC<IDeleteMerchantDialogProps> = ({ isOpen, onClose }) => {
  const { merchant: merchantStore } = useContext(StoreContext);
  const history = useHistory();

  const submit = async () => {
    if (!merchantStore.data?.id) return;

    const isDeleted = await merchantStore.deleteMerchant(merchantStore.data.id);

    onClose();

    if (isDeleted) history.push(MERCHANTS);
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>Delete Merchant</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this merchant?</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="contained" color="primary" onClick={submit}>
          Delete
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(DeleteMerchantDialog);
