/* eslint-disable */
import MobxReactForm from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';

interface Props {
  fields: Object[];
  hooks?: Object;
  options?: Object;
}

interface Rules {
  function: () => void;
  message: string;
}

const rules: Record<string, Rules> = {};

const createForm = ({ fields, hooks, options = {} }: Props) => {
  const plugins = {
    ...{
      dvr: dvr({
        package: validatorjs,
        extend: ({ validator }: any) => {
          Object.keys(rules).forEach((key) => validator.register(key, rules[key].function, rules[key].message));
        },
      }),
    },
  };

  const form = new MobxReactForm(
    { fields },
    {
      hooks,
      plugins,
      options: { validateOnInit: false, validateOnBlur: false, validateOnChange: true, ...options },
    },
  );

  return form;
};

export default createForm;
