import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react';
import React, { FC, MouseEvent, useContext } from 'react';

import TextField from 'components/MobxForm/TextField';
import { StoreContext } from 'stores';
import createForm from 'utils/createForm';

import fields from './fields';

interface IEditMerchantDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

interface IEditMerchantDialogState {
  form: any;
  onEnter: () => void;
}

const EditMerchantDialog: FC<IEditMerchantDialogProps> = ({ isOpen, onClose }) => {
  const { merchant: merchantStore } = useContext(StoreContext);
  const state = useLocalObservable<IEditMerchantDialogState>(() => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    form: createForm({ fields }),

    onEnter: () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      state.form.set('default', merchantStore.data);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      state.form.reset();
    },
  }));

  const submit = async (event: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    state.form.onSubmit(event);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (!state.form.isValid || !merchantStore.data?.id) return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const editedMerchant = await merchantStore.editMerchant(merchantStore.data.id, {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      ...state.form.values(),
    });

    if (editedMerchant) {
      void merchantStore.fetch(merchantStore.data.id);
    }

    onClose();
  };

  return (
    <Dialog fullWidth open={isOpen} TransitionProps={{ onEnter: state.onEnter }} onClose={onClose}>
      <DialogTitle>Edit Merchant</DialogTitle>
      <DialogContent>
        <Box>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
          <TextField fullWidth field={state.form.$('name')} />
        </Box>
        <Box>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
          <TextField fullWidth multiline field={state.form.$('description')} rows="4" />
        </Box>
      </DialogContent>
      <DialogActions>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */}
        <Button autoFocus disabled={state.form.isDefault} variant="contained" color="primary" onClick={submit}>
          Save
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(EditMerchantDialog);
