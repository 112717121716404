export const AUTH0_DOMAIN = process.env.REACT_APP_DS_AUTH0_DOMAIN || window.DS_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_DS_AUTH0_CLIENT_ID || window.DS_AUTH0_CLIENT_ID;
export const AUTH0_AUDIENCE = process.env.REACT_APP_DS_AUTH0_AUDIENCE || window.DS_AUTH0_AUDIENCE;

export const env = process.env.REACT_APP_DS_ENVIRONMENT || window.DS_ENVIRONMENT || 'local';

export const getCDNUrl = () => {
  switch (env) {
    case 'local':
      return 'http://localhost:50000';
    default:
      return '/prxApi';
  }
};
