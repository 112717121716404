import { createApiCall } from 'api';
import * as urls from 'api/urls';
import { TContentForm, TContent } from 'types/Content';

export class ContentsApi {
  constructor(private readonly api: ReturnType<typeof createApiCall>) {}

  createContent = async ({
    contentForm,
    siteId,
  }: {
    contentForm: TContentForm;
    siteId: string;
  }) =>
    this.api.request<TContentForm & { siteId: string }, TContent>({
      url: `${urls.CONTENT_SERVICE}${urls.SITES}/${siteId}${urls.CONTENTS}`,
      method: 'post',
      data: { ...contentForm, siteId },
    });

  editContent = async ({
    contentId,
    contentForm,
    siteId,
  }: {
    contentId: string;
    contentForm: TContentForm;
    siteId: string;
  }) =>
    this.api.request<TContentForm & { siteId: string }, TContent>({
      url: `${urls.CONTENT_SERVICE}${urls.SITES}/${siteId}${urls.CONTENTS}/${contentId}`,
      method: 'put',
      data: { ...contentForm, siteId },
    });

  getContent = async ({
    contentId,
    siteId,
  }: {
    contentId: string;
    siteId: string;
  }) =>
    this.api.request<null, TContent>({
      url: `${urls.CONTENT_SERVICE}${urls.SITES}/${siteId}${urls.CONTENTS}/${contentId}`,
    });

  getContents = async ({
    siteId,
  }: {
    siteId: string;
  }) =>
    this.api.request<null, TContent[]>({
      url: `${urls.CONTENT_SERVICE}${urls.SITES}/${siteId}${urls.CONTENTS}`,
    });

  removeContent = async ({
    contentId,
    siteId,
  }: {
    contentId: string;
    siteId: string;
  }) =>
    this.api.request<TContentForm & { siteId: string }, TContent>({
      url: `${urls.CONTENT_SERVICE}${urls.SITES}/${siteId}${urls.CONTENTS}/${contentId}`,
      method: 'delete',
    });
}
