import { DeleteForever as DeleteForeverIcon } from '@mui/icons-material';
import { Box, Button, Paper, Typography, Grid, Menu, MenuItem, IconButton } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react';
import React, { FC, MouseEvent, useContext } from 'react';

import FormSelect from 'components/MobxForm/Select';
import { StoreContext } from 'stores';

interface IPropertiesProps {
  form: any;
  addPropertyField: (name: string) => void;
  removePropertyField: (name: string) => void;
}

interface IPropertiesState {
  anchorEl: null | HTMLElement;
  usedPropertyNames: string[];
  propertyNames: string[];

  setAnchorEl: (el: null | HTMLElement) => void;
}

const Properties: FC<IPropertiesProps> = ({ form, addPropertyField, removePropertyField }) => {
  const { site, productProperties } = useContext(StoreContext);
  const state = useLocalObservable<IPropertiesState>(() => ({
    anchorEl: null,

    get usedPropertyNames(): string[] {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      return form.$('properties').map((field: any) => field.name);
    },
    get propertyNames(): string[] {
      return (site.data?.allowedProperties || []).filter((propertyName) => !state.usedPropertyNames.includes(propertyName));
    },

    setAnchorEl: (el) => {
      state.anchorEl = el;
    },
  }));

  const handleAddPropertyClick = (event: MouseEvent<HTMLElement>) => {
    state.setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    state.setAnchorEl(null);
  };

  const handleMenuItemClick = async (name: string) => {
    addPropertyField(name);
    handleClose();
  };

  return (
    <>
      {(site.data?.allowedProperties || []).length > 0 && (
        <Box>
          <Paper variant="outlined">
            <Box pl={2}>
              <Typography variant="overline">Properties</Typography>
            </Box>
            <Box pl={2} pr={2}>
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
              {form.$('properties').map((field: any) => (
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                <Grid key={field.name} container alignItems="center" spacing={1}>
                  <Grid item xs={5}>
                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                    <Typography>{field.name}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <FormSelect
                      fullWidth
                      options={
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                        productProperties.propertiesMap.get(field.name)?.map((prop) => ({ label: prop.value, value: prop.id })) ||
                        []
                      }
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      field={field}
                      label="Value"
                    />
                  </Grid>
                  <Grid item container xs={2} justifyContent="flex-end">
                    <Grid item>
                      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access */}
                      <IconButton onClick={() => { removePropertyField(field.name); }}>
                        <DeleteForeverIcon fontSize="large" color="primary" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Box>
            <Box pl={2}>
              <Button
                variant="outlined"
                color="primary"
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                disabled={form.$('properties').size === site.data?.allowedProperties?.length}
                onClick={handleAddPropertyClick}
              >
                Add Property
              </Button>
              <Menu
                keepMounted
                anchorEl={state.anchorEl}
                PaperProps={{
                  style: {
                    width: 150,
                  },
                }}
                open={Boolean(state.anchorEl)}
                onClose={handleClose}
              >
                {state.propertyNames.map((option) => (
                  <MenuItem key={option} onClick={async () => handleMenuItemClick(option)}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Paper>
        </Box>
      )}
    </>
  );
};

export default observer(Properties);
