import { types } from 'mobx-state-tree';

const Site = types.model({
  id: types.string,
  createdAt: types.string,
  updatedAt: types.string,
  url: types.string,
  description: types.string,
  allowedProperties: types.maybeNull(types.array(types.string)),
});

export default Site;
