import { useQuery } from '@tanstack/react-query';

import { EQueries } from 'config/queryClient';
import { useApi } from 'context/ApiContext';

type TContentsQueryParams = { siteId: string };

export const useContentsQuery = ({ siteId }: TContentsQueryParams) => {
  const { contentsApi } = useApi();

  return useQuery([EQueries.CONTENTS], async () => contentsApi.getContents({ siteId }));
};