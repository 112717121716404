import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Paper,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import 'suneditor/src/assets/css/suneditor-contents.css';
import 'suneditor/src/assets/css/suneditor.css';
import { formatBlock, fontSize, paragraphStyle, list, table, link, blockquote, align } from 'suneditor/src/plugins';
import SunEditor from 'suneditor-react';
import { z } from 'zod';

import { UploadAssets } from 'components/UploadAssets';
import { EQueries } from 'config/queryClient';
import { useNotifications } from 'hooks/useNotification';
import { useCreateContentMutation } from 'mutations/content/useCreateContentMutation';
import { useEditContentMutation } from 'mutations/content/useEditContentMutation';
import { useContentQuery } from 'queries/content/useContentQuery';
import { useSiteQuery } from 'queries/site/useSiteQuery';

type TManageContentDialogProps = {
  isNew?: boolean;
  isOpen: boolean;
  onClose: () => void;
};

type TManageContentDialogParams = {
  id: string;
  contentId: string;
};

export const ManageContentDialogFormValidationSchema = z.object({
  title: z.string().min(1).max(100),
  description: z.string(),
  text: z.string().min(1),
  route: z.string().min(1).max(100),
  imagesNames: z.array(z.string()),
});

export const ManageContentDialog: React.FC<TManageContentDialogProps> = (
  props,
) => {
  const {
    control,
    handleSubmit: handleContentSubmit,
    reset,
    formState,
  } = useForm<z.infer<typeof ManageContentDialogFormValidationSchema>>({
    mode: 'onChange',
    resolver: zodResolver(ManageContentDialogFormValidationSchema),
    defaultValues: {
      title: '',
      description: '',
      text: '',
      route: '',
      imagesNames: [],
    },
  });
  const { id: siteId, contentId } = useParams<TManageContentDialogParams>();
  const queryClient = useQueryClient();
  const { showWarningNotification } = useNotifications();

  const siteQuery = useSiteQuery({
    siteId,
    enabled: true,
  });

  const contentQuery = useContentQuery({
    contentId,
    siteId,
    enabled: !props.isNew,
  });

  const createContentMutation = useCreateContentMutation(() => {
    void queryClient.invalidateQueries([EQueries.CONTENTS], { exact: true });
  });

  const editContentMutation = useEditContentMutation(() => {
    void queryClient.invalidateQueries([EQueries.CONTENTS], { exact: true });
    void queryClient.invalidateQueries([EQueries.CONTENTS, contentId]);
  });

  const createOrErditContent = async (
    contentForm: z.infer<typeof ManageContentDialogFormValidationSchema>,
  ) => {
    if (props.isNew) {
      await createContentMutation.mutateAsync({ siteId, contentForm });

      showWarningNotification(`Don't forget invalidate everything that you need, e.g:
        ${String(siteQuery.data?.data.body.url)}/api/revalidate?tag={needed-tag}`, { autoHideDuration: null });
    } else {
      if (!contentQuery.data) return;

      await editContentMutation.mutateAsync({
        siteId,
        contentForm,
        contentId: contentQuery.data.data.body.id,
      });

      showWarningNotification(`Don't forget invalidate the content:
        ${String(siteQuery.data?.data.body.url)}/api/revalidate?tag=content-${contentForm.route}`, { autoHideDuration: null });
    }

    props.onClose();
  };

  const close = () => {
    props.onClose();
  };

  const fillForm = () => {
    if (!contentQuery.data) return;

    reset({
      ...contentQuery.data.data.body,
      imagesNames: contentQuery.data.data.body.imagesNames ?? [],
    });
  };

  return (
    <Dialog
      fullWidth
      open={props.isOpen}
      TransitionProps={{ onExited: close, onEntering: fillForm }}
      maxWidth="lg"
      onClose={close}
    >
      <DialogTitle>{`${props.isNew ? 'Create' : 'Edit'} Content`}</DialogTitle>
      <DialogContent>
        <Box mt={1}>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Title"
                error={Boolean(formState.errors.title?.message)}
                helperText={formState.errors.title?.message}
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <SunEditor
                setAllPlugins={false}
                setOptions={{
                  height: '320px',
                  buttonList: [
                    ['undo', 'redo', 'removeFormat'],
                    ['formatBlock', 'align', 'bold', 'underline', 'italic', 'strike', 'list', 'link', 'blockquote', 'table', 'codeView'],
                  ],
                  plugins: [formatBlock, fontSize, paragraphStyle, list, table, link, blockquote, align],
                  linkProtocol: '/',
                }}
                setContents={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            name="text"
            control={control}
            render={({ field }) => (
              <SunEditor
                setAllPlugins={false}
                setOptions={{
                  height: '450px',
                  buttonList: [
                    ['undo', 'redo', 'removeFormat'],
                    ['formatBlock', 'align', 'bold', 'underline', 'italic', 'strike', 'list', 'link', 'blockquote', 'table', 'codeView'],
                  ],
                  plugins: [formatBlock, fontSize, paragraphStyle, list, table, link, blockquote, align],
                  linkProtocol: '/',
                }}
                setContents={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            name="route"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Route"
                error={Boolean(formState.errors.route?.message)}
                helperText={formState.errors.route?.message}
              />
            )}
          />
        </Box>
        <Box mb={1}>
          <Paper variant="outlined">
            <Box pl={2}>
              <Typography variant="overline">Photos</Typography>
            </Box>
            <Box pl={2} pr={2}>
              <Controller
                name="imagesNames"
                control={control}
                render={({ field }) => (
                  <UploadAssets
                    imagesNames={field.value}
                    onSetImagesNames={field.onChange}
                  />
                )}
              />
            </Box>
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          color="primary"
          onClick={handleContentSubmit(createOrErditContent)}
        >
          {props.isNew ? 'Create' : 'Update'}
        </Button>
        <Button variant="outlined" onClick={props.onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
