const fields = [
  {
    name: 'url',
    label: 'URL',
    rules: 'required',
  },
  {
    name: 'description',
    label: 'Description',
  },
  {
    name: 'allowedProperties',
    label: 'Allowed Properties',
  },
];

export default fields;
