import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
import { Typography, Paper, Toolbar, Button, Grid } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react';
import { FC, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import EditProductDialog from 'components/ManageProductDialog';
import PageContainer from 'components/PageContainer';
import { SITES, PRODUCTS } from 'router/path';
import { StoreContext } from 'stores';

import DeleteProductDialog from './components/DeleteProductDialog';
import ProductInfo from './components/ProductInfo';
import useStyles from './styles';

interface IProductParams {
  id: string;
  productId: string;
}

interface IProductState {
  isEditDialogOpen: boolean;
  isDeleteDialogOpen: boolean;

  openEditDialog: () => void;
  closeEditDialog: () => void;
  openDeleteDialog: () => void;
  closeDeleteDialog: () => void;
}

const Product: FC = () => {
  const { id, productId } = useParams<IProductParams>();
  const { product: productStore, site: siteStore, productProperties, merchants } = useContext(StoreContext);
  const state = useLocalObservable<IProductState>(() => ({
    isEditDialogOpen: false,
    isDeleteDialogOpen: false,

    openEditDialog: () => {
      state.isEditDialogOpen = true;
    },
    closeEditDialog: () => {
      state.isEditDialogOpen = false;
    },
    openDeleteDialog: () => {
      state.isDeleteDialogOpen = true;
    },
    closeDeleteDialog: () => {
      state.isDeleteDialogOpen = false;
    },
  }));
  const history = useHistory();
  const styles = useStyles();

  useEffect(() => {
    const fetch = async () => {
      const product = await productStore.fetch(id, productId);

      if (!product) history.push(`${SITES}/${id}`);

      void siteStore.fetch(id);
      void productProperties.fetch();
      void merchants.fetch();
    };

    void fetch();

    return () => {
      productStore.clear();
      siteStore.clear();
      productProperties.clear();
      merchants.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToProducts = () => {
    history.push(`${SITES}/${id}${PRODUCTS}`);
  };

  return (
    <PageContainer>
      <Typography variant="h1">Product</Typography>
      <Typography variant="overline">{productStore.data?.name}</Typography>
      <Paper elevation={8} className={styles.paper}>
        <>
          <Toolbar variant="dense" className={styles.toolbar}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={goToProducts}>
                  Back to products
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={state.openEditDialog}>
                  Edit
                </Button>
                <Button variant="outlined" onClick={state.openDeleteDialog}>
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
          {productStore.data && <ProductInfo {...productStore.data} />}
        </>
      </Paper>

      <EditProductDialog isOpen={state.isEditDialogOpen} onClose={state.closeEditDialog} />
      <DeleteProductDialog isOpen={state.isDeleteDialogOpen} onClose={state.closeDeleteDialog} />
    </PageContainer>
  );
};

export default observer(Product);
