import { useAuth0 } from '@auth0/auth0-react';
import { Typography, TableContainer, Paper, Table, TableBody, Toolbar, Button } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react';
import React, { useEffect, useContext, FC } from 'react';

import PageContainer from 'components/PageContainer';
import { StoreContext } from 'stores';
import { TAuth0User } from 'stores/models/Auth0User';
import { isSuperAdmin } from 'utils/admin';

import CreateSiteDialog from './components/CreateSiteDialog';
import SitesTableHead from './components/SitesTableHead';
import SitesTableRow from './components/SitesTableRow';
import useStyles from './styles';

interface ISitesState {
  isCreateDialogOpen: boolean;

  openCreateDialog: () => void;
  closeCreateDialog: () => void;
}

const Sites: FC = () => {
  const { sites, productProperties, processing } = useContext(StoreContext);
  const state = useLocalObservable<ISitesState>(() => ({
    isCreateDialogOpen: false,

    openCreateDialog: () => {
      state.isCreateDialogOpen = true;
    },
    closeCreateDialog: () => {
      state.isCreateDialogOpen = false;
    },
  }));
  const { user } = useAuth0<TAuth0User>();
  const styles = useStyles();

  useEffect(() => {
    const fetch = () => {
      void sites.fetch();
      void productProperties.fetch();
    };

    fetch();

    return () => {
      sites.clear();
      productProperties.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer>
      <Typography variant="h1">Sites</Typography>
      <Typography variant="overline">List of the allowed sites</Typography>
      <Paper elevation={8} className={styles.paper}>
        <Toolbar variant="dense" className={styles.toolbar}>
          {isSuperAdmin(user) && (
            <Button variant="contained" color="primary" disabled={processing.isLoading} onClick={state.openCreateDialog}>
              Add new site
            </Button>
          )}
        </Toolbar>
        <TableContainer>
          <Table>
            <SitesTableHead />
            <TableBody>
              {sites.list.map((site) => (
                <SitesTableRow {...{ key: site.id, site }} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <CreateSiteDialog isOpen={state.isCreateDialogOpen} onClose={state.closeCreateDialog} />
    </PageContainer>
  );
};

export default observer(Sites);
