import { useAuth0 } from '@auth0/auth0-react';
import React, { useMemo, useContext } from 'react';

import { createApiCall } from 'api';
import { ArticlesApi } from 'api/ArticlesApi';
import { AssetsApi } from 'api/AssetsApi';
import { ContentsApi } from 'api/ContentsApi';
import { SitesApi } from 'api/SitesApi';
import { TagsApi } from 'api/TagsApi';

export type TApiContext = {
  assetsApi: AssetsApi;
  articlesApi: ArticlesApi;
  tagsApi: TagsApi;
  contentsApi: ContentsApi;
  sitesApi: SitesApi;
};

export const ApiContext = React.createContext<TApiContext>({} as unknown as TApiContext);

export const ApiProvider: React.FC<{ children: React.ReactNode | React.ReactNode[] }> = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const apiContextValue = useMemo<TApiContext>(() => {
    const api = createApiCall(getAccessTokenSilently);

    return {
      assetsApi: new AssetsApi(api),
      articlesApi: new ArticlesApi(api),
      tagsApi: new TagsApi(api),
      contentsApi: new ContentsApi(api),
      sitesApi: new SitesApi(api),
    };
  }, [getAccessTokenSilently]);

  return <ApiContext.Provider value={apiContextValue}>{props.children}</ApiContext.Provider>;
};

export const useApi = () => useContext(ApiContext);
