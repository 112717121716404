import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
import { Typography, Paper, Toolbar, Button, Grid, Box, ImageList, ImageListItem, Chip } from '@mui/material';
import parse from 'html-react-parser';
import keyBy from 'lodash/keyBy';
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { CDN_SERVICE, IMAGES } from 'api/urls';
import LabelValueView from 'components/LabelValueView';
import { Loader } from 'components/Loader';
import { ManageArticleDialog } from 'components/ManageArticleDialog';
import PageContainer from 'components/PageContainer';
import { getCDNUrl } from 'config';
import dirNames from 'consts/image';
import { useBoolean } from 'hooks/useBoolean';
import { useArticleQuery } from 'queries/article/useArticleQuery';
import { useTagsQuery } from 'queries/tag/useTagsQuery';
import { ARTICLES, SITES } from 'router/path';
import getFormattedDate from 'utils';

import { RemoveArticleDialog } from './components/RemoveArticleDialog';

interface IArticleParams {
  id: string;
  articleId: string;
}

export const Article: React.FC = () => {
  const { id: siteId, articleId } = useParams<IArticleParams>();
  const history = useHistory();
  const [isEditDialogOpen, openEditDialog, closeEditDialog] = useBoolean();
  const [isRemoveDialogOpen, openRemoveDialog, closeRemoveDialog] = useBoolean();

  const articleQuery = useArticleQuery({
    articleId,
    siteId,
    enabled: true,
  });

  const tagsQuery = useTagsQuery({ siteId });
  const tagsById = keyBy(tagsQuery.data?.data.body, (tag) => tag.id);

  const goToArticles = () => {
    history.push(`${SITES}/${siteId}${ARTICLES}`);
  };

  return (
    <PageContainer>
      <Typography variant="h1">Article</Typography>
      <Typography variant="overline">{articleQuery.data?.data.body.title}</Typography>
      <Paper elevation={8} sx={{ marginTop: (theme) => theme.spacing(3), paddingBottom: (theme) => theme.spacing(1) }}>
        <>
          <Toolbar variant="dense" sx={{
            justifyContent: 'space-between',
            backgroundColor: (theme) => theme.palette.grey[100],
            borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`,
          }}>
            <Grid item>
              <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={goToArticles}>
                Back to articles
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={openEditDialog}>
                Edit
              </Button>
              <Button variant="outlined" sx={{ marginLeft: (theme) => theme.spacing(1) }} onClick={openRemoveDialog}>
                Delete
              </Button>
            </Grid>
          </Toolbar>
          {!articleQuery.isFetching && articleQuery.data && (
            <>
              <LabelValueView label="id" value={articleQuery.data.data.body.id} />
              <LabelValueView label="createdAt" value={getFormattedDate(articleQuery.data.data.body.createdAt)} />
              <LabelValueView label="updatedAt" value={getFormattedDate(articleQuery.data.data.body.updatedAt)} />
              <LabelValueView label="title" value={articleQuery.data.data.body.title} />
              <LabelValueView label="route" value={articleQuery.data.data.body.route} />
              <Box m={3}>
                <Paper variant="outlined">
                  <Box pl={2}>
                    <Typography variant="overline">Tags</Typography>
                  </Box>
                  <Box pl={2} pr={2}>
                    {(articleQuery.data.data.body.tagIds ?? []).map((tagId) => (
                      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                      <Chip key={tagId} label={tagsById[tagId]?.name} sx={{ marginRight: 1, marginBottom: 1 }} />
                    ))}
                  </Box>
                </Paper>
              </Box>
              <Box m={3}>
                <Paper variant="outlined">
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography sx={{ padding: (theme) => `${theme.spacing(1)} ${theme.spacing(3)}`, fontWeight: 'bold' }}>description</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      {parse(articleQuery.data.data.body.description)}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography sx={{ padding: (theme) => `${theme.spacing(1)} ${theme.spacing(3)}`, fontWeight: 'bold' }}>text</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      {parse(articleQuery.data.data.body.text)}
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
              <Box m={3}>
                <Paper variant="outlined">
                  <Box pl={2}>
                    <Typography variant="overline">Photos</Typography>
                  </Box>
                  <Box pl={2} pr={2}>
                    <ImageList sx={{ flexWrap: 'nowrap', transform: 'translateZ(0)' }} cols={3}>
                      {(articleQuery.data.data.body.imagesNames ?? []).map((imageName) => (
                        <ImageListItem key={imageName}>
                          <img src={`${getCDNUrl()}${CDN_SERVICE}${IMAGES}/${dirNames[2]}/${imageName}`} alt="" />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </Box>
                </Paper>
              </Box>
            </>
          )}
          {articleQuery.isRefetching && <Loader />}
        </>
      </Paper>

      <ManageArticleDialog isOpen={isEditDialogOpen} onClose={closeEditDialog} />
      <RemoveArticleDialog isOpen={isRemoveDialogOpen} onClose={closeRemoveDialog} />
    </PageContainer>
  );
};
