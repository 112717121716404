import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  label: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    fontWeight: 'bold',
  },
  value: {
    padding: theme.spacing(1),
  },
}));

export default useStyles;
