import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react';
import React, { FC, MouseEvent, useContext } from 'react';

import TextField from 'components/MobxForm/TextField';
import { StoreContext } from 'stores';
import createForm from 'utils/createForm';

import fields from './fields';

interface ICreateProductPropertyDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

interface ICreateProductPropertyDialogState {
  form: any;
  onEnter: () => void;
}

const CreateProductPropertyDialog: FC<ICreateProductPropertyDialogProps> = ({ isOpen, onClose }) => {
  const state = useLocalObservable<ICreateProductPropertyDialogState>(() => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    form: createForm({ fields }),

    onEnter: () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      state.form.reset();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
      state.form.each((field: any) => field.resetValidation());
    },
  }));
  const { productProperty, productProperties } = useContext(StoreContext);

  const submit = async (event: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    state.form.onSubmit(event);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (!state.form.isValid) return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    await productProperty.createProductProperty({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      ...state.form.values(),
    });
    void productProperties.fetch();
    onClose();
  };

  return (
    <Dialog fullWidth open={isOpen} TransitionProps={{ onEnter: state.onEnter }} onClose={onClose}>
      <DialogTitle>Create Product Property</DialogTitle>
      <DialogContent>
        <Box>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
          <TextField fullWidth field={state.form.$('name')} />
        </Box>
        <Box>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
          <TextField fullWidth field={state.form.$('value')} />
        </Box>
        <Box>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
          <TextField fullWidth field={state.form.$('code')} />
        </Box>
      </DialogContent>
      <DialogActions>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */}
        <Button autoFocus disabled={state.form.isDefault} variant="contained" color="primary" onClick={submit}>
          Create
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(CreateProductPropertyDialog);
