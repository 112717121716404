import { observer } from 'mobx-react';

import BaseSelect, { ISelectProps as BaseSelectProps } from 'components/Select';
import { mapFieldToProps } from 'utils/mapFieldToProps';

interface ISelectProps extends BaseSelectProps {
  field: any;
}

const Select = (props: ISelectProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { field, variant = 'outlined', ...rest } = props;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  return <BaseSelect {...mapFieldToProps(field)} error={field.error} variant={variant} {...rest} />;
};

export default observer(Select);
