const fields = [
  {
    name: 'name',
    label: 'Name',
    rules: 'required',
  },
  {
    name: 'description',
    label: 'Description',
  },
];

export default fields;
