import { useMutation } from '@tanstack/react-query';

import { useApi } from 'context/ApiContext';
import { TContentForm } from 'types/Content';

export const useCreateContentMutation = (onSuccess?: () => void) => {
  const { contentsApi } = useApi();

  return useMutation(async ({ contentForm, siteId }: { contentForm: TContentForm; siteId: string }) => contentsApi.createContent({ contentForm, siteId }), {
    onSuccess,
  });
};