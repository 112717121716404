import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react';
import { FC, MouseEvent, useContext } from 'react';

import TextField from 'components/MobxForm/TextField';
import { StoreContext } from 'stores';
import createForm from 'utils/createForm';

import fields from './fields';

interface IEditProductPropertyDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

interface IEditProductPropertyDialogState {
  form: any;
  onEnter: () => void;
}

const EditProductPropertyDialog: FC<IEditProductPropertyDialogProps> = ({ isOpen, onClose }) => {
  const { productProperty: productPropertyStore } = useContext(StoreContext);
  const state = useLocalObservable<IEditProductPropertyDialogState>(() => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    form: createForm({ fields }),

    onEnter: () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      state.form.set('default', productPropertyStore.data);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
      state.form.reset();
    },
  }));

  const submit = async (event: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    state.form.onSubmit(event);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (!state.form.isValid) return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const editedProductProperty = await productPropertyStore.editProductProperty(productPropertyStore.data?.id as string, {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      ...state.form.values(),
    });

    if (editedProductProperty) void productPropertyStore.fetch(productPropertyStore.data?.id as string);

    onClose();
  };

  return (
    <Dialog fullWidth open={isOpen} TransitionProps={{ onEnter: state.onEnter }} onClose={onClose}>
      <DialogTitle>Edit Product Property</DialogTitle>
      <DialogContent>
        <Box>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
          <TextField fullWidth field={state.form.$('name')} />
        </Box>
        <Box>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
          <TextField fullWidth field={state.form.$('value')} />
        </Box>
        <Box>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
          <TextField fullWidth field={state.form.$('code')} />
        </Box>
      </DialogContent>
      <DialogActions>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */}
        <Button autoFocus disabled={state.form.isDefault} variant="contained" color="primary" onClick={submit}>
          Save
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(EditProductPropertyDialog);
