import { TableHead, TableRow, TableCell } from '@mui/material';
import { FC } from 'react';

export const headers = [
  { id: 'name', label: 'Name' },
  { id: 'vendorCode', label: 'Code' },
  { id: 'route', label: 'Route' },
  { id: 'quantity', label: 'Quantity' },
  { id: 'price', label: 'Price' },
  { id: 'merchantPrice', label: 'Merchant Price' },
  { id: 'currency', label: 'Currency' },
];

const ProductTableHead: FC = () => (
  <TableHead>
    <TableRow>
      {headers.map((header) => (
        <TableCell key={header.id}>{header.label}</TableCell>
      ))}
    </TableRow>
  </TableHead>
);

export default ProductTableHead;
