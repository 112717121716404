import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { EQueries } from 'config/queryClient';
import { useRemoveArticleMutation } from 'mutations/article/useRemoveArticleMutation';
import { SITES, ARTICLES } from 'router/path';

interface IRemoveArticleDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

interface IRemoveArticleDialogParams {
  id: string;
  articleId: string;
}

export const RemoveArticleDialog: FC<IRemoveArticleDialogProps> = ({ isOpen, onClose }) => {
  const history = useHistory();
  const { id: siteId, articleId } = useParams<IRemoveArticleDialogParams>();
  const queryClient = useQueryClient();

  const removeArticleMutation = useRemoveArticleMutation(() => {
    void queryClient.invalidateQueries([EQueries.ARTICLES], { exact: true });
  });

  const remove = async () => {
    await removeArticleMutation.mutateAsync({ siteId, articleId });
    history.push(`${SITES}/${siteId}${ARTICLES}`);
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>Delete Article</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this article?</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="contained" color="primary" onClick={remove}>
          Delete
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
