const fields = [
  {
    name: 'name',
    label: 'Name',
    rules: 'required',
  },
  {
    name: 'vendorCode',
    label: 'Vendor Code',
    rules: 'required',
  },
  {
    name: 'route',
    label: 'Route',
    rules: 'required',
  },
  {
    name: 'description',
    label: 'Description',
  },
  {
    name: 'properties',
    label: 'Product Properties',
  },
  {
    name: 'price',
    label: 'Price',
    type: 'number',
    rules: 'required|numeric|min:0',
    input: (value: number) => value.toString(),
    output: (value: string) => Number(value),
  },
  {
    name: 'oldPrice',
    label: 'Old Price',
    type: 'number',
    rules: 'numeric|min:0',
    input: (value: number) => value.toString(),
    output: (value: string) => Number(value),
  },
  {
    name: 'currency',
    label: 'Currency',
    rules: 'required',
  },
  {
    name: 'quantity',
    label: 'Quantity',
    type: 'number',
    rules: 'required|numeric|min:-1',
    input: (value: number) => value.toString(),
    output: (value: string) => Number(value),
  },
  {
    name: 'merchantId',
    label: 'Merchant',
    rules: 'required',
  },
  {
    name: 'merchantVendorCode',
    label: 'Merchant Vendor Code',
    rules: 'required',
  },
  {
    name: 'merchantPrice',
    label: 'Merchant Price',
    type: 'number',
    rules: 'required|numeric|min:0',
    input: (value: number) => value.toString(),
    output: (value: string) => Number(value),
  },
  {
    name: 'imagesNames',
    value: [],
  },
];

export default fields;
