import { DeleteForever as DeleteForeverIcon } from '@mui/icons-material';
import { Button, Box, ImageList, ImageListItem, ImageListItemBar, IconButton } from '@mui/material';
import { observer } from 'mobx-react';
import React, { FC, useContext } from 'react';

import { CDN_SERVICE, IMAGES } from 'api/urls';
import { getCDNUrl } from 'config';
import { dirNames } from 'consts/image';
import { StoreContext } from 'stores';

import useStyles from './styles';

interface IPhotosProps {
  imagesNames: string[] | null;
  setImagesNames: (imagesNames: string[]) => void;
}

// Deprecated! Use UploadAssets instead
const Photos: FC<IPhotosProps> = ({ imagesNames, setImagesNames }) => {
  const { product } = useContext(StoreContext);
  const styles = useStyles();

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = [...(event.target.files || [])];
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('files', file, file.name);
    });

    const resp = await product.uploadPhotos(formData);

    if (resp) {
      setImagesNames([...(imagesNames ?? []), ...(resp.data.body).map((asset) => asset.name)]);
    }
  };

  const onRemove = (imageName: string) => {
    setImagesNames((imagesNames ?? []).filter((img) => img !== imageName));
  };

  const onMakeFirst = (imageName: string) => {
    setImagesNames([imageName, ...(imagesNames ?? []).filter((img) => img !== imageName)]);
  };

  return (
    <>
      <input multiple accept="image/*" className={styles.input} id="photos" type="file" onChange={onChange} />
      <label htmlFor="photos">
        <Button variant="contained" color="primary" component="span">
          Upload
        </Button>
      </label>
      <Box mt={1} className={styles.imageWrapper}>
        <ImageList className={styles.imageList} cols={3}>
          {(imagesNames ?? []).map((imageName: string, imagesNamesIndx: number) => (
            <ImageListItem key={imageName}>
              <img src={`${getCDNUrl()}${CDN_SERVICE}${IMAGES}/${dirNames[2]}/${imageName}`} alt="" />
              <ImageListItemBar
                actionIcon={
                  <>
                    {imagesNamesIndx > 0 && (
                      <Button variant="outlined" color="primary" onClick={() => { onMakeFirst(imageName); }}>
                        Make First
                      </Button>
                    )}
                    <IconButton onClick={() => { onRemove(imageName); }}>
                      <DeleteForeverIcon color="primary" />
                    </IconButton>
                  </>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </>
  );
};

export default observer(Photos);
