import { createApiCall } from 'api';
import * as urls from 'api/urls';
import { TSite } from 'types/Site';

export class SitesApi {
  constructor(private readonly api: ReturnType<typeof createApiCall>) {}

  getSite = async ({
    siteId,
  }: {
    siteId: string;
  }) =>
    this.api.request<null, TSite>({
      url: `${urls.MAIN_SERVICE}${urls.SITES}/${siteId}`,
    });
}
