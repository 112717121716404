import { Typography, TableContainer, Paper, Table, TableBody, Toolbar, Button } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react';
import React, { useEffect, useContext, FC } from 'react';

import PageContainer from 'components/PageContainer';
import { StoreContext } from 'stores';

import CreateMerchantDialog from './components/CreateMerchantDialog';
import MerchantsTableHead from './components/MerchantsTableHead';
import MerchantsTableRow from './components/MerchantsTableRow';
import useStyles from './styles';

interface IMerchantsState {
  isCreateDialogOpen: boolean;

  openCreateDialog: () => void;
  closeCreateDialog: () => void;
}

const Merchants: FC = () => {
  const { merchants } = useContext(StoreContext);
  const state = useLocalObservable<IMerchantsState>(() => ({
    isCreateDialogOpen: false,

    openCreateDialog: () => {
      state.isCreateDialogOpen = true;
    },
    closeCreateDialog: () => {
      state.isCreateDialogOpen = false;
    },
  }));
  const styles = useStyles();

  useEffect(() => {
    const fetch = () => {
      void merchants.fetch();
    };

    fetch();

    return () => { merchants.clear(); };
  }, [merchants]);

  return (
    <PageContainer>
      <Typography variant="h1">Merchants</Typography>
      <Typography variant="overline">List of merchants</Typography>
      <Paper elevation={8} className={styles.paper}>
        <Toolbar variant="dense" className={styles.toolbar}>
          <Button variant="contained" color="primary" onClick={state.openCreateDialog}>
            Add new merchant
          </Button>
        </Toolbar>
        <TableContainer>
          <Table>
            <MerchantsTableHead />
            <TableBody>
              {merchants.list.map((merchant) => (
                <MerchantsTableRow {...{ key: merchant.id, merchant }} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <CreateMerchantDialog isOpen={state.isCreateDialogOpen} onClose={state.closeCreateDialog} />
    </PageContainer>
  );
};

export default observer(Merchants);
