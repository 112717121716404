import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Typography, Paper, Toolbar, Button, Link, Box } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react';
import { FC, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import PageContainer from 'components/PageContainer';
import { ARTICLES, SITES, PRODUCTS, CONTENTS } from 'router/path';
import { StoreContext } from 'stores';

import DeleteSiteDialog from './components/DeleteSiteDialog';
import EditSiteDialog from './components/EditSiteDialog';
import SiteInfo from './components/SiteInfo';
import useStyles from './styles';

interface ISiteParams {
  id: string;
}

interface ISiteState {
  isEditDialogOpen: boolean;
  isDeleteDialogOpen: boolean;
  isCreateProductDialogOpen: boolean;

  openEditDialog: () => void;
  closeEditDialog: () => void;
  openDeleteDialog: () => void;
  closeDeleteDialog: () => void;
  openCreateProductDialog: () => void;
  closeCreateProductDialog: () => void;
}

const Site: FC = () => {
  const { id } = useParams<ISiteParams>();
  const { site: siteStore, products, productProperties } = useContext(StoreContext);
  const state = useLocalObservable<ISiteState>(() => ({
    isEditDialogOpen: false,
    isDeleteDialogOpen: false,
    isCreateProductDialogOpen: false,

    openEditDialog: () => {
      state.isEditDialogOpen = true;
    },
    closeEditDialog: () => {
      state.isEditDialogOpen = false;
    },
    openDeleteDialog: () => {
      state.isDeleteDialogOpen = true;
    },
    closeDeleteDialog: () => {
      state.isDeleteDialogOpen = false;
    },
    openCreateProductDialog: () => {
      state.isCreateProductDialogOpen = true;
    },
    closeCreateProductDialog: () => {
      state.isCreateProductDialogOpen = false;
    },
  }));
  const history = useHistory();
  const styles = useStyles();

  useEffect(() => {
    const fetch = async () => {
      const site = await siteStore.fetch(id);

      if (!site) {
        history.push(SITES);

        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      siteStore.data && void products.fetch(siteStore.data.id);
      void productProperties.fetch();
    };

    void fetch();

    return () => {
      siteStore.clear();
      products.clear();
      productProperties.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToArticles = () => {
    history.push(`${SITES}/${id}${ARTICLES}`);
  };

  const goToProducts = () => {
    history.push(`${SITES}/${id}${PRODUCTS}`);
  };

  const goToContents = () => {
    history.push(`${SITES}/${id}${CONTENTS}`);
  };

  return (
    <PageContainer>
      <Typography variant="h1">Site</Typography>
      <Box display="flex" alignItems="center">
        <Typography variant="overline" component="div" display="flex">
          <Link href={siteStore.data?.url} target='_blank'>{siteStore.data?.url}</Link>
        </Typography>
        <OpenInNewIcon sx={{ fontSize: 12, marginLeft: 1 }} color="warning" />
      </Box>
      <Paper elevation={8} className={styles.paper}>
        <>
          <Toolbar variant="dense" className={styles.toolbar}>
            <Button variant="contained" color="primary" onClick={state.openEditDialog}>
              Edit
            </Button>
            <Button variant="outlined" onClick={state.openDeleteDialog}>
              Delete
            </Button>
          </Toolbar>
          {siteStore.data && <SiteInfo {...siteStore.data} />}
          <Toolbar variant="dense" sx={{ backgroundColor: (theme) => theme.palette.grey[100] }}>
            <Button variant="contained" color="primary" onClick={goToProducts}>
              View products
            </Button>
            <Button variant="contained" color="primary" sx={{ marginLeft: (theme) => theme.spacing(1) }} onClick={goToArticles}>
              View articles
            </Button>
            <Button variant="contained" color="primary" sx={{ marginLeft: (theme) => theme.spacing(1) }} onClick={goToContents}>
              View contents
            </Button>
          </Toolbar>
        </>
      </Paper>

      <EditSiteDialog isOpen={state.isEditDialogOpen} onClose={state.closeEditDialog} />
      <DeleteSiteDialog isOpen={state.isDeleteDialogOpen} onClose={state.closeDeleteDialog} />
    </PageContainer>
  );
};

export default observer(Site);
