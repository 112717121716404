import { types, flow, getParent, toGenerator, Instance, applySnapshot } from 'mobx-state-tree';

import { api } from 'api';
import * as urls from 'api/urls';
import { TRootStore } from 'types/store';
import { hasStringMessage } from 'utils/hasStringMessage';

import Asset from './models/Asset';
import ProductModel from './models/Product';

type TAssetsType = Instance<typeof Asset>[];

const Product = types
  .model({
    data: types.maybe(ProductModel),
  })
  .actions((self) => ({
    fetch: flow(function* fetch(siteId: string, productId: string) {
      try {
        const resp = yield* toGenerator(
          api.request<null, Instance<typeof ProductModel>>({
            url: `${urls.MAIN_SERVICE}${urls.SITES}/${siteId}${urls.PRODUCTS}/${productId}`,
          }),
        );

        self.data = resp.data.body;

        return resp;
      } catch (error: unknown) {
        const { processing } = getParent<TRootStore>(self);

        if (hasStringMessage(error)) {
          processing.setError(error.message);
        }

        return null;
      }
    }),
    createProduct: flow(function* createProduct(siteId: string, data: Instance<typeof ProductModel>) {
      try {
        const resp = yield* toGenerator(
          api.request<Instance<typeof ProductModel>, Instance<typeof ProductModel>>({
            url: `${urls.MAIN_SERVICE}${urls.SITES}/${siteId}${urls.PRODUCTS}`,
            method: 'post',
            data,
          }),
        );

        return resp;
      } catch (error: unknown) {
        const { processing } = getParent<TRootStore>(self);

        if (hasStringMessage(error)) {
          processing.setError(error.message);
        }

        return null;
      }
    }),
    editProduct: flow(function* editProduct(siteId: string, productId: string, data: Instance<typeof ProductModel>) {
      try {
        const resp = yield* toGenerator(
          api.request<Instance<typeof ProductModel>, Instance<typeof ProductModel>>({
            url: `${urls.MAIN_SERVICE}${urls.SITES}/${siteId}${urls.PRODUCTS}/${productId}`,
            method: 'put',
            data,
          }),
        );

        self.data = resp.data.body;

        return resp;
      } catch (error: unknown) {
        const { processing } = getParent<TRootStore>(self);

        if (hasStringMessage(error)) {
          processing.setError(error.message);
        }

        return null;
      }
    }),
    deleteProduct: flow(function* deleteProduct(siteId: string, productId: string) {
      try {
        yield* toGenerator(
          api.request<null, null>({
            url: `${urls.MAIN_SERVICE}${urls.SITES}/${siteId}${urls.PRODUCTS}/${productId}`,
            method: 'delete',
          }),
        );
        applySnapshot(self, {});

        return true;
      } catch (error: unknown) {
        const { processing } = getParent<TRootStore>(self);

        if (hasStringMessage(error)) {
          processing.setError(error.message);
        }

        return null;
      }
    }),
    uploadPhotos: flow(function* uploadPhotos(data: FormData) {
      try {
        const resp = yield* toGenerator(
          api.request<FormData, TAssetsType>({
            url: `${urls.CDN_SERVICE}${urls.ASSETS}`,
            method: 'post',
            data,
          }),
        );

        return resp;
      } catch (error: unknown) {
        const { processing } = getParent<TRootStore>(self);

        if (hasStringMessage(error)) {
          processing.setError(error.message);
        }

        return null;
      }
    }),
    clear() {
      applySnapshot(self, {});
    },
  }));

export default Product;
