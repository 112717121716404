import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Toolbar, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { ReactNode, FC } from 'react';

import LinearLoader from './components/LinearLoader';
import Menu from './components/Menu';
import UserSection from './components/UserSection';
import useStyles from './styles';

interface ITemplateProps {
  children: ReactNode;
}

const Template: FC<ITemplateProps> = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  const styles = useStyles();

  return (
    <>
      <LinearLoader />
      {isAuthenticated && (
        <AppBar position="fixed" className={styles.appBar}>
          <Toolbar className={styles.toolbar}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item xs={2} className={styles.logo}>
                <Typography variant="h1">DS Admin</Typography>
              </Grid>
              <Menu />
              <UserSection />
            </Grid>
          </Toolbar>
        </AppBar>
      )}
      <main>{children}</main>
    </>
  );
};

export default observer(Template);
