import { IMiddlewareHandler } from 'mobx-state-tree';

import { AUTH } from 'router/path';

const HandleUnauthorized: IMiddlewareHandler = (call, next) => {
  if (call.type === 'flow_resume_error') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const isUnauthorized = call.args.some((arg) => arg.status === 401);

    if (isUnauthorized) {
      window.location.href = AUTH;

      return;
    }
  }

  next(call);
};

export default HandleUnauthorized;
