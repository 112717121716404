const fields = [
  {
    name: 'name',
    label: 'Name',
    rules: 'required',
  },
  {
    name: 'value',
    label: 'Value',
    rules: 'required',
  },
  {
    name: 'code',
    label: 'Code',
  },
];

export default fields;
