import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { useSnackbar, OptionsObject } from 'notistack';
import { useEffect, useContext } from 'react';

import { StoreContext } from 'stores';

const Notifier = () => {
  const { processing } = useContext(StoreContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    autorun(() => {
      processing.errorList.forEach((error) => {
        if (processing.displayedErrors.includes(error.key)) return;

        enqueueSnackbar(error.message, error.options as OptionsObject);
        processing.setDisplayedErrors(error.key).removeError(error);
      });
    });

    autorun(() => {
      processing.successActionList.forEach((action) => {
        if (processing.displayedActions.includes(action.key)) return;

        enqueueSnackbar(action.message, action.options as OptionsObject);
        processing.setDisplayedAction(action.key).removeAction(action);
      });
    });
  }, [enqueueSnackbar, processing]);

  return null;
};

export default observer(Notifier);
