/* eslint-disable @typescript-eslint/naming-convention */
import makeStyles from '@mui/styles/makeStyles';

import { APP_HEADER_HEIGHT } from 'theme/const';

const useStyles = makeStyles(() => ({
  appBar: {
    '&.MuiPaper-elevation4': {
      boxShadow: 'none',
    },
  },
  toolbar: {
    '&.MuiToolbar-regular': {
      minHeight: APP_HEADER_HEIGHT,
    },
  },

  logo: {
    textTransform: 'uppercase',
  },
}));

export default useStyles;
