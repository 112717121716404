import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  progress: {
    position: 'absolute',
    width: '100%',
    height: 2,
    zIndex: theme.zIndex.appBar + 1,
  },
}));

export default useStyles;
