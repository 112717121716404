import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  versionWrapper: {
    marginLeft: theme.spacing(2),
  },

  version: {
    fontSize: 10,
  },
}));

export default useStyles;
