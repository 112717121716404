import { TableHead, TableRow, TableCell } from '@mui/material';
import React, { FC } from 'react';

export const headers = [
  { id: 'id', label: 'ID' },
  { id: 'createdAt', label: 'Created At' },
  { id: 'url', label: 'URL' },
  { id: 'description', label: 'Description' },
  { id: 'allowedProperties', label: 'Allowed Properties' },
];

const SitesTableHead: FC = () => (
  <TableHead>
    <TableRow>
      {headers.map((header) => (
        <TableCell key={header.id}>{header.label}</TableCell>
      ))}
    </TableRow>
  </TableHead>
);

export default SitesTableHead;
