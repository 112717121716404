import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Toolbar,
  Button,
} from '@mui/material';
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { CDN_SERVICE, IMAGES } from 'api/urls';
import { ManageContentDialog } from 'components/ManageContentDialog';
import PageContainer from 'components/PageContainer';
import { getCDNUrl } from 'config';
import dirNames from 'consts/image';
import { useBoolean } from 'hooks/useBoolean';
import { useContentsQuery } from 'queries/content/useContentsQuery';
import { SITES, CONTENTS } from 'router/path';
import getFormattedDate from 'utils';

type TContentsParams = {
  id: string;
};

export const Contents: React.FC = () => {
  const { id: siteId } = useParams<TContentsParams>();
  const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] = useBoolean();
  const history = useHistory();

  const contentsQuery = useContentsQuery({ siteId });

  const openContent = (contentId: string) => {
    history.push(`${SITES}/${siteId}${CONTENTS}/${contentId}`);
  };

  const goToSite = () => {
    history.push(`${SITES}/${siteId}`);
  };

  return (
    <>
      <PageContainer>
        <Typography variant="h1">Contents</Typography>
        <Typography variant="overline">List of contents</Typography>
        <Paper elevation={8} sx={{ marginTop: (theme) => theme.spacing(3) }}>
          <Toolbar
            variant="dense"
            sx={{
              justifyContent: 'space-between',
              backgroundColor: (theme) => theme.palette.grey[100],
              borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`,
            }}
          >
            <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={goToSite}>
              Back to site
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={openCreateDialog}
            >
              Add new content
            </Button>
          </Toolbar>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Img</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>CreatedAt</TableCell>
                  <TableCell>UpdatedAt</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Route</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contentsQuery.data?.data.body.map((content) => (
                  <TableRow key={content.id} sx={{ cursor: 'pointer' }} onClick={() => { openContent(content.id); }}>
                    <TableCell>
                      {content.imagesNames?.[0] && (
                        <img width={100} src={`${getCDNUrl()}${CDN_SERVICE}${IMAGES}/${dirNames[2]}/${(content.imagesNames[0])}`} alt="" />
                      )}
                    </TableCell>
                    <TableCell>{content.id}</TableCell>
                    <TableCell>{getFormattedDate(content.createdAt)}</TableCell>
                    <TableCell>{getFormattedDate(content.updatedAt)}</TableCell>
                    <TableCell>{content.title}</TableCell>
                    <TableCell>{content.route}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </PageContainer>

      <ManageContentDialog isNew isOpen={isCreateDialogOpen} onClose={closeCreateDialog} />
    </>
  );
};
