import { useAuth0 } from '@auth0/auth0-react';
import { LinearProgress, Box } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { api } from 'api';
import Template from 'components/Template';
import { useNotifications } from 'hooks/useNotification';
import Authorization from 'pages/Authorization';
import Router from 'router';
import { StoreContext } from 'stores';
import { hasStringMessage } from 'utils/hasStringMessage';

const App = () => {
  const { loginWithRedirect, getAccessTokenSilently, isAuthenticated, isLoading, error } = useAuth0();
  const [isTokenFetched, setIsTokenFetched] = useState(false);
  const { processing } = useContext(StoreContext);
  const { showErrorNotification } = useNotifications();

  useEffect(() => {
    const notify = (rejection: PromiseRejectionEvent) => {
      const message = hasStringMessage(rejection.reason) ? rejection.reason.message : 'Unknown error occured';

      showErrorNotification(message);
    };

    window.addEventListener('unhandledrejection', notify);

    return () => {
      window.removeEventListener('unhandledrejection', notify);
    };
  }, [showErrorNotification]);

  useEffect(() => {
    if (isLoading || error) return;

    if (!isAuthenticated) {
      void loginWithRedirect();

      return;
    }

    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently();

        api.setToken(token);
        setIsTokenFetched(true);
      } catch (tokenError: unknown) {
        if  (hasStringMessage(tokenError)) 
          processing.setError(tokenError.message);
      }
    };

    void getToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (!error && (isLoading || !isTokenFetched)) {
    return (
      <Box>
        <LinearProgress />
      </Box>
    );
  }

  if (error) return <Authorization />;

  return (
    <BrowserRouter>
      <Template>
        <Router />
      </Template>
    </BrowserRouter>
  );
};

export default App;
