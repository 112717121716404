import { TableRow, TableCell } from '@mui/material';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { PRODUCT_PROPERTIES } from 'router/path';
import ProductProperty from 'stores/models/ProductProperty';
import { getFormattedDate } from 'utils';

import useStyles from './styles';

interface IProductPropertiesTableRowProps {
  productProperty: Instance<typeof ProductProperty>;
}

const ProductPropertiesTableRow: FC<IProductPropertiesTableRowProps> = ({ productProperty: { id, createdAt, updatedAt, name, value, code } }) => {
  const history = useHistory();
  const styles = useStyles();

  const openProductProperty = () => {
    history.push(`${PRODUCT_PROPERTIES}/${id}`);
  };

  return (
    <TableRow key={id} className={styles.row} onClick={openProductProperty}>
      <TableCell>{id}</TableCell>
      <TableCell>{getFormattedDate(createdAt)}</TableCell>
      <TableCell>{getFormattedDate(updatedAt)}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{value}</TableCell>
      <TableCell>{code}</TableCell>
    </TableRow>
  );
};

export default observer(ProductPropertiesTableRow);
