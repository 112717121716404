import { types, Instance } from 'mobx-state-tree';

import Merchant from './Merchant';
import ProductProperty from './ProductProperty';

const Product = types.model({
  id: types.string,
  createdAt: types.string,
  updatedAt: types.string,
  siteId: types.string,
  name: types.string,
  vendorCode: types.string,
  route: types.string,
  description: types.string,
  imagesNames: types.maybeNull(types.array(types.string)),
  properties: types.maybeNull(types.array(ProductProperty)),
  price: types.number,
  oldPrice: types.number,
  currency: types.string,
  quantity: types.number,
  merchantId: types.string,
  merchant: types.maybeNull(Merchant),
  merchantVendorCode: types.string,
  merchantPrice: types.number,
});

export type TProduct = Instance<typeof Product>;

export default Product;
