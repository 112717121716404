import { DeleteForever as DeleteForeverIcon } from '@mui/icons-material';
import { Button, Box, ImageList, ImageListItem, ImageListItemBar, IconButton, InputBase } from '@mui/material';
import React from 'react';

import { CDN_SERVICE, IMAGES } from 'api/urls';
import { getCDNUrl } from 'config';
import { dirNames } from 'consts/image';
import { useUploadAssetsMutation } from 'mutations/asset/useUploadAssetsMutation';

type TUploadPhotosProps = {
  imagesNames: string[];
  onSetImagesNames: (imagesNames: string[]) => void;
};

export const UploadAssets: React.FC<TUploadPhotosProps> = ({ imagesNames, onSetImagesNames }) => {
  const uploadAssetsMutation = useUploadAssetsMutation();

  const uploadAndSet = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = [...(event.target.files || [])];
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('files', file, file.name);
    });

    const resp = await uploadAssetsMutation.mutateAsync(formData);

    onSetImagesNames([...imagesNames, ...resp.data.body.map((asset) => asset.name)]);
  };

  const removeAsset = (imageName: string) => {
    onSetImagesNames(imagesNames.filter((img) => img !== imageName));
  };

  const makeAssetFirst = (imageName: string) => {
    onSetImagesNames([imageName, ...imagesNames.filter((img) => img !== imageName)]);
  };

  return (
    <>
      <InputBase inputProps={{ multiple: true, accept: 'image/*' }} sx={{ display: 'none' }} id="photos" type="file" onChange={uploadAndSet} />
      <label htmlFor="photos">
        <Button variant="contained" color="primary" component="span">
          Upload
        </Button>
      </label>
      <Box mt={1} display="flex" flexWrap="wrap" justifyContent="space-around" overflow="hidden" sx={{ backgroundColor: (theme) => theme.palette.background.paper }}>
        <ImageList sx={{ width: '100%' }} cols={3}>
          {imagesNames.map((imageName: string, imagesNamesIndx: number) => (
            <ImageListItem key={imageName}>
              <img src={`${getCDNUrl()}${CDN_SERVICE}${IMAGES}/${dirNames[2]}/${imageName}`} alt="" />
              <ImageListItemBar
                actionIcon={
                  <>
                    {imagesNamesIndx > 0 && (
                      <Button variant="outlined" color="primary" onClick={() => { makeAssetFirst(imageName); }}>
                        Make First
                      </Button>
                    )}
                    <IconButton onClick={() => { removeAsset(imageName); }}>
                      <DeleteForeverIcon color="primary" />
                    </IconButton>
                  </>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </>
  );
};
