import { useQuery } from '@tanstack/react-query';

import { EQueries } from 'config/queryClient';
import { useApi } from 'context/ApiContext';

type TTagsQueryParams = { siteId: string };

export const useTagsQuery = ({ siteId }: TTagsQueryParams) => {
  const { tagsApi } = useApi();

  return useQuery([EQueries.TAGS], async () => tagsApi.getTags({ siteId }));
};