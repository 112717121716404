import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, SelectChangeEvent } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react';
import React, { FC, MouseEvent, useContext } from 'react';

import Select from 'components/MobxForm/Select';
import TextField from 'components/MobxForm/TextField';
import { StoreContext } from 'stores';
import createForm from 'utils/createForm';

import fields from './fields';

interface ICreateSiteDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

interface ICreateSiteDialogState {
  form: any;
  selectedProductPropertyNames: string[];

  onEnter: () => void;
  setProductPropertyNames: (event: SelectChangeEvent<unknown>) => void;
}

const CreateSiteDialog: FC<ICreateSiteDialogProps> = ({ isOpen, onClose }) => {
  const state = useLocalObservable<ICreateSiteDialogState>(() => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    form: createForm({ fields }),
    selectedProductPropertyNames: [],

    onEnter: () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      state.form.reset();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
      state.form.each((field: any) => field.resetValidation());
    },
    setProductPropertyNames: (event) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      state.form.$('allowedProperties').set(event.target.value as string[]);
      state.selectedProductPropertyNames = event.target.value as string[];
    },
  }));
  const { site, sites, productProperties } = useContext(StoreContext);

  const submit = async (event: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    state.form.onSubmit(event);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (!state.form.isValid) return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    await site.createSite({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      ...state.form.values(),
    });
    void sites.fetch();
    onClose();
  };

  return (
    <Dialog fullWidth open={isOpen} TransitionProps={{ onEnter: state.onEnter }} onClose={onClose}>
      <DialogTitle>Create Site</DialogTitle>
      <DialogContent>
        <Box mt={1}>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
          <TextField fullWidth field={state.form.$('url')} />
        </Box>
        <Box>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
          <TextField fullWidth multiline field={state.form.$('description')} rows="4" />
        </Box>
        <Box>
          <Select
            fullWidth
            multiple
            value={state.selectedProductPropertyNames}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            field={state.form.$('allowedProperties')}
            options={productProperties.propertyNames}
            renderValue={(selected) => (selected as string[]).join(', ')}
            onChange={state.setProductPropertyNames}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */}
        <Button autoFocus disabled={state.form.isDefault} variant="contained" color="primary" onClick={submit}>
          Create
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(CreateSiteDialog);
