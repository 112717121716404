import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { SITES } from 'router/path';
import { StoreContext } from 'stores';

interface IDeleteSiteDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const DeleteSiteDialog: FC<IDeleteSiteDialogProps> = ({ isOpen, onClose }) => {
  const { site: siteStore } = useContext(StoreContext);
  const history = useHistory();

  const submit = async () => {
    const isDeleted = await siteStore.deleteSite(siteStore.data?.id as string);

    onClose();

    if (isDeleted) history.push(SITES);
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>Delete Site</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this site?</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="contained" color="primary" onClick={submit}>
          Delete
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(DeleteSiteDialog);
