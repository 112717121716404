import { TableRow, TableCell } from '@mui/material';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { SITES } from 'router/path';
import Site from 'stores/models/Site';
import { getFormattedDate } from 'utils';

import useStyles from './styles';

interface ISitesTableRowProps {
  site: Instance<typeof Site>;
}

const SitesTableRow: FC<ISitesTableRowProps> = ({ site: { id, createdAt, url, description, allowedProperties } }) => {
  const history = useHistory();
  const styles = useStyles();

  const openSite = () => {
    history.push(`${SITES}/${id}`);
  };

  return (
    <TableRow key={id} className={styles.row} onClick={openSite}>
      <TableCell>{id}</TableCell>
      <TableCell>{getFormattedDate(createdAt)}</TableCell>
      <TableCell>{url}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell>{allowedProperties?.join(', ')}</TableCell>
    </TableRow>
  );
};

export default observer(SitesTableRow);
