import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
import { Typography, Paper, Toolbar, Button, Grid, Box, ImageList, ImageListItem } from '@mui/material';
import parse from 'html-react-parser';
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { CDN_SERVICE, IMAGES } from 'api/urls';
import LabelValueView from 'components/LabelValueView';
import { Loader } from 'components/Loader';
import { ManageContentDialog } from 'components/ManageContentDialog';
import PageContainer from 'components/PageContainer';
import { getCDNUrl } from 'config';
import dirNames from 'consts/image';
import { useBoolean } from 'hooks/useBoolean';
import { useContentQuery } from 'queries/content/useContentQuery';
import { CONTENTS, SITES } from 'router/path';
import getFormattedDate from 'utils';

import { RemoveContentDialog } from './components/RemoveContentDialog';

interface IContentParams {
  id: string;
  contentId: string;
}

export const Content: React.FC = () => {
  const { id: siteId, contentId } = useParams<IContentParams>();
  const history = useHistory();
  const [isEditDialogOpen, openEditDialog, closeEditDialog] = useBoolean();
  const [isRemoveDialogOpen, openRemoveDialog, closeRemoveDialog] = useBoolean();

  const contentQuery = useContentQuery({
    contentId,
    siteId,
    enabled: true,
  });

  const goToContents = () => {
    history.push(`${SITES}/${siteId}${CONTENTS}`);
  };

  return (
    <PageContainer>
      <Typography variant="h1">Content</Typography>
      <Typography variant="overline">{contentQuery.data?.data.body.title}</Typography>
      <Paper elevation={8} sx={{ marginTop: (theme) => theme.spacing(3), paddingBottom: (theme) => theme.spacing(1) }}>
        <>
          <Toolbar variant="dense" sx={{
            justifyContent: 'flex-end',
            backgroundColor: (theme) => theme.palette.grey[100],
            borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`,
          }}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button variant="outlined" startIcon={<ChevronLeftIcon />} onClick={goToContents}>
                  Back to contents
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={openEditDialog}>
                  Edit
                </Button>
                <Button variant="outlined" sx={{ marginLeft: (theme) => theme.spacing(1) }} onClick={openRemoveDialog}>
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
          {!contentQuery.isFetching && contentQuery.data && (
            <>
              <LabelValueView label="id" value={contentQuery.data.data.body.id} />
              <LabelValueView label="createdAt" value={getFormattedDate(contentQuery.data.data.body.createdAt)} />
              <LabelValueView label="updatedAt" value={getFormattedDate(contentQuery.data.data.body.updatedAt)} />
              <LabelValueView label="title" value={contentQuery.data.data.body.title} />
              <LabelValueView label="route" value={contentQuery.data.data.body.route} />
              <Box m={3}>
                <Paper variant="outlined">
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography sx={{ padding: (theme) => `${theme.spacing(1)} ${theme.spacing(3)}`, fontWeight: 'bold' }}>description</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      {parse(contentQuery.data.data.body.description)}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography sx={{ padding: (theme) => `${theme.spacing(1)} ${theme.spacing(3)}`, fontWeight: 'bold' }}>text</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      {parse(contentQuery.data.data.body.text)}
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
              <Box m={3}>
                <Paper variant="outlined">
                  <Box pl={2}>
                    <Typography variant="overline">Photos</Typography>
                  </Box>
                  <Box pl={2} pr={2}>
                    <ImageList sx={{ flexWrap: 'nowrap', transform: 'translateZ(0)' }} cols={3}>
                      {(contentQuery.data.data.body.imagesNames ?? []).map((imageName) => (
                        <ImageListItem key={imageName}>
                          <img src={`${getCDNUrl()}${CDN_SERVICE}${IMAGES}/${dirNames[2]}/${imageName}`} alt="" />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </Box>
                </Paper>
              </Box>
            </>
          )}
          {contentQuery.isRefetching && <Loader />}
        </>
      </Paper>

      <ManageContentDialog isOpen={isEditDialogOpen} onClose={closeEditDialog} />
      <RemoveContentDialog isOpen={isRemoveDialogOpen} onClose={closeRemoveDialog} />
    </PageContainer>
  );
};
