import { MutationCache, QueryCache, QueryClient, QueryClientConfig } from '@tanstack/react-query';

const config: QueryClientConfig = {
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      // If this query has flag in meta object, skip this
      if (query.meta && !query.meta.shouldBeHandledByGlobalErrorHandler) return;

      throw error;
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, variables, context, mutation) => {
      // If this mutation has an onError defined, skip this
      if (mutation.options.onError) return;

      throw error;
    },
  }),
};

export const queryClient = new QueryClient(config);

export const EQueries = {
  ARTICLES: 'articles',
  TAGS: 'tags',
  CONTENTS: 'contents',
  SITES: 'sites',
} as const;
