import axios, { AxiosRequestConfig, AxiosError, AxiosResponse, AxiosRequestHeaders } from 'axios';

interface IResponse<T> {
  message: string;
  body: T;
}

export const DEFAULT_LIMIT = 50;

type TRequestHeaders = Omit<AxiosRequestHeaders, 'Authorization'> & {
  Authorization?: `Bearer ${string}`;
};

export type TRequestParams = Record<string, string | string[] | undefined> | URLSearchParams;

export interface IRequestConfig<Body> extends Omit<AxiosRequestConfig<Body>, 'headers' | 'params'> {
  headers?: TRequestHeaders;
  params?: TRequestParams;
}

interface IServerResponse<Res, Req> extends Omit<AxiosResponse<Res, Req>, 'data'> {
  // eslint-disable-next-line id-denylist
  data: IResponse<Res>;
}

axios.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    let resp = error.message ? error : { ...error, message: 'Network Error' };

    if (error.response) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      resp =
        typeof error.response.data === 'string'
          ? { message: error.response.data || error.response.statusText, status: error.response.status }
          : error.response.data || { message: error.response.statusText, status: error.response.status };
    }

    return Promise.reject(resp); // when error will return message from server
  },
);

// TODO remove it after stores cleanup
// is used in stores
export const api = {
  token: '',
  request: async <Req, Res>(config: IRequestConfig<Req>): Promise<IServerResponse<Res, Req>> => {
    const { method = 'GET', headers, ...rest } = config;

    return axios.request<IRequestConfig<Req>, IServerResponse<Res, Req>>({
      baseURL: '/prxApi',
      method,
      headers: {
        Authorization: `Bearer ${api.token}`,
        ...headers,
      },
      ...rest,
    });
  },
  setToken: (token: string) => {
    api.token = token;
  },
};

// is used in api layer
export const createApiCall = (getToken: () => Promise<string>) => ({
  request: async <Req, Res>(config: IRequestConfig<Req>): Promise<IServerResponse<Res, Req>> => {
    const { method = 'GET', headers, ...rest } = config;
    const token = await getToken();

    return axios.request<IRequestConfig<Req>, IServerResponse<Res, Req>>({
      baseURL: '/prxApi',
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
      },
      ...rest,
    });
  },
});
