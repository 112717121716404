/* eslint-disable @typescript-eslint/naming-convention */
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  toolbar: {
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.grey[100],
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    marginBottom: theme.spacing(1),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
}));

export default useStyles;
