import { useAuth0 } from '@auth0/auth0-react';
import { Typography, Grid, Link } from '@mui/material';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import PageContainer from 'components/PageContainer';
import { DASHBOARD } from 'router/path';

const Authorization = () => {
  const { isAuthenticated } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) history.push(DASHBOARD);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageContainer>
      <Typography gutterBottom component="h1" variant="h2" align="center" color="textPrimary">
        No Access
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          You are not authorized to use the DS Admin. <Link href="/">Try Again</Link>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Authorization;
