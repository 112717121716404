import { Typography, Paper, Toolbar, Button } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react';
import React, { FC, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import PageContainer from 'components/PageContainer';
import { MERCHANTS } from 'router/path';
import { StoreContext } from 'stores';

import DeleteMerchantDialog from './components/DeleteMerchantDialog';
import EditMerchantDialog from './components/EditMerchantDialog';
import MerchantInfo from './components/MerchantInfo';
import useStyles from './styles';

interface IMerchantParams {
  id: string;
}

interface IMerchantState {
  isEditDialogOpen: boolean;
  isDeleteDialogOpen: boolean;

  openEditDialog: () => void;
  closeEditDialog: () => void;
  openDeleteDialog: () => void;
  closeDeleteDialog: () => void;
}

const Merchant: FC = () => {
  const { id } = useParams<IMerchantParams>();
  const { merchant: merchantStore } = useContext(StoreContext);
  const state = useLocalObservable<IMerchantState>(() => ({
    isEditDialogOpen: false,
    isDeleteDialogOpen: false,

    openEditDialog: () => {
      state.isEditDialogOpen = true;
    },
    closeEditDialog: () => {
      state.isEditDialogOpen = false;
    },
    openDeleteDialog: () => {
      state.isDeleteDialogOpen = true;
    },
    closeDeleteDialog: () => {
      state.isDeleteDialogOpen = false;
    },
  }));
  const history = useHistory();
  const styles = useStyles();

  useEffect(() => {
    const fetch = async () => {
      const site = await merchantStore.fetch(id);

      if (!site) history.push(MERCHANTS);
    };

    void fetch();

    return () => { merchantStore.clear(); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer>
      <Typography variant="h1">Merchant</Typography>
      <Typography variant="overline">{merchantStore.data?.name}</Typography>
      <Paper elevation={8} className={styles.paper}>
        <>
          <Toolbar variant="dense" className={styles.toolbar}>
            <Button variant="contained" color="primary" onClick={state.openEditDialog}>
              Edit
            </Button>
            <Button variant="outlined" onClick={state.openDeleteDialog}>
              Delete
            </Button>
          </Toolbar>
          {merchantStore.data && <MerchantInfo {...merchantStore.data} />}
        </>
      </Paper>

      <EditMerchantDialog isOpen={state.isEditDialogOpen} onClose={state.closeEditDialog} />
      <DeleteMerchantDialog isOpen={state.isDeleteDialogOpen} onClose={state.closeDeleteDialog} />
    </PageContainer>
  );
};

export default observer(Merchant);
