import { Typography } from '@mui/material';

import PageContainer from 'components/PageContainer';

export default function NotFoundPage() {
  return (
    <PageContainer>
      <Typography gutterBottom component="h1" variant="h2" align="center" color="textPrimary">
        Page Not Found
      </Typography>
    </PageContainer>
  );
}
