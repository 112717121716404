import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Paper,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import 'suneditor/src/assets/css/suneditor-contents.css';
import 'suneditor/src/assets/css/suneditor.css';
import { formatBlock, fontSize, paragraphStyle, list, table, link, blockquote, align } from 'suneditor/src/plugins';
import SunEditor from 'suneditor-react';
import { z } from 'zod';

import { UploadAssets } from 'components/UploadAssets';
import { EQueries } from 'config/queryClient';
import { useNotifications } from 'hooks/useNotification';
import { useCreateArticleMutation } from 'mutations/article/useCreateArticleMutation';
import { useEditArticleMutation } from 'mutations/article/useEditArticleMutation';
import { useArticleQuery } from 'queries/article/useArticleQuery';
import { useSiteQuery } from 'queries/site/useSiteQuery';
import { useTagsQuery } from 'queries/tag/useTagsQuery';

import { TagsAutocomplete } from './TagsAutocomplete';

type TManageArticleDialogProps = {
  isNew?: boolean;
  isOpen: boolean;
  onClose: () => void;
};

type TManageArticleDialogParams = {
  id: string;
  articleId: string;
};

export const ManageArticleDialogFormValidationSchema = z.object({
  title: z.string().min(1).max(100),
  description: z.string().min(1),
  text: z.string().min(1),
  route: z.string().min(1).max(100),
  imagesNames: z.array(z.string()),
  tagIds: z.array(z.object({ id: z.string() })),
});

export const ManageArticleDialog: React.FC<TManageArticleDialogProps> = (
  props,
) => {
  const {
    control,
    handleSubmit: handleArticleSubmit,
    reset,
    formState,
  } = useForm<z.infer<typeof ManageArticleDialogFormValidationSchema>>({
    mode: 'onChange',
    resolver: zodResolver(ManageArticleDialogFormValidationSchema),
    defaultValues: {
      title: '',
      description: '',
      text: '',
      route: '',
      imagesNames: [],
      tagIds: [],
    },
  });
  const { id: siteId, articleId } = useParams<TManageArticleDialogParams>();
  const queryClient = useQueryClient();
  const { showWarningNotification } = useNotifications();

  const siteQuery = useSiteQuery({
    siteId,
    enabled: true,
  });

  const articleQuery = useArticleQuery({
    articleId,
    siteId,
    enabled: !props.isNew,
  });

  const tagsQuery = useTagsQuery({ siteId });

  const createArticleMutation = useCreateArticleMutation(() => {
    void queryClient.invalidateQueries([EQueries.ARTICLES], { exact: true });
  });

  const editArticleMutation = useEditArticleMutation(() => {
    void queryClient.invalidateQueries([EQueries.ARTICLES], { exact: true });
    void queryClient.invalidateQueries([EQueries.ARTICLES, articleId]);
  });

  const createOrErditArticle = async (
    articleForm: z.infer<typeof ManageArticleDialogFormValidationSchema>,
  ) => {
    if (props.isNew) {
      await createArticleMutation.mutateAsync({ siteId, articleForm });

      showWarningNotification(`Don't forget invalidate a list of the articles:
        ${String(siteQuery.data?.data.body.url)}/api/revalidate?tag=articles 
        and articles by given tag:
        ${String(siteQuery.data?.data.body.url)}/api/revalidate?tag=articlesByTag-${articleForm.tagIds[0].id}`, { autoHideDuration: null });
      showWarningNotification(`Don't forget invalidate a list of the tags if you created new:
        ${String(siteQuery.data?.data.body.url)}/api/revalidate?tag=tags`, { autoHideDuration: null });
    } else {
      if (!articleQuery.data) return;

      await editArticleMutation.mutateAsync({
        siteId,
        articleForm,
        articleId: articleQuery.data.data.body.id,
      });

      showWarningNotification(`Don't forget invalidate a list of the articles if you changed title or description:
        ${String(siteQuery.data?.data.body.url)}/api/revalidate?tag=articles 
        and articles by given tag:
        ${String(siteQuery.data?.data.body.url)}/api/revalidate?tag=articlesByTag-${articleForm.tagIds[0].id}`, { autoHideDuration: null });
      showWarningNotification(`Don't forget invalidate the article:
        ${String(siteQuery.data?.data.body.url)}/api/revalidate?tag=${articleForm.route}`, { autoHideDuration: null });
      showWarningNotification(`Don't forget invalidate a list of the tags if you created new:
        ${String(siteQuery.data?.data.body.url)}/api/revalidate?tag=tags`, { autoHideDuration: null });
    }

    props.onClose();
  };

  const close = () => {
    props.onClose();
  };

  const fillForm = () => {
    if (!articleQuery.data) return;

    const tags = tagsQuery.data?.data.body ?? [];

    const chosenTags = (articleQuery.data.data.body.tagIds ?? []).map(chosenTagId => tags.find(tag => tag.id === chosenTagId));

    reset({
      ...articleQuery.data.data.body,
      imagesNames: articleQuery.data.data.body.imagesNames ?? [],
      tagIds: chosenTags,
    });
  };

  return (
    <Dialog
      fullWidth
      open={props.isOpen}
      TransitionProps={{ onExited: close, onEntering: fillForm }}
      maxWidth="lg"
      onClose={close}
    >
      <DialogTitle>{`${props.isNew ? 'Create' : 'Edit'} Article`}</DialogTitle>
      <DialogContent>
        <Box mt={1}>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Title"
                error={Boolean(formState.errors.title?.message)}
                helperText={formState.errors.title?.message}
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <SunEditor
                setAllPlugins={false}
                setOptions={{
                  height: '320px',
                  buttonList: [
                    ['undo', 'redo', 'removeFormat'],
                    ['formatBlock', 'align', 'bold', 'underline', 'italic', 'strike', 'list', 'link', 'blockquote', 'table', 'codeView'],
                  ],
                  plugins: [formatBlock, fontSize, paragraphStyle, list, table, link, blockquote, align],
                  linkProtocol: '/',
                }}
                setContents={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            name="text"
            control={control}
            render={({ field }) => (
              <SunEditor
                setAllPlugins={false}
                setOptions={{
                  height: '450px',
                  buttonList: [
                    ['undo', 'redo', 'removeFormat'],
                    ['formatBlock', 'align', 'bold', 'underline', 'italic', 'strike', 'list', 'link', 'blockquote', 'table', 'codeView'],
                  ],
                  plugins: [formatBlock, fontSize, paragraphStyle, list, table, link, blockquote, align],
                  linkProtocol: '/',
                }}
                setContents={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            name="tagIds"
            control={control}
            render={({ field }) => (
              <TagsAutocomplete siteId={siteId} {...field} />
            )}
          />
        </Box>
        <Box>
          <Controller
            name="route"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Route"
                error={Boolean(formState.errors.route?.message)}
                helperText={formState.errors.route?.message}
              />
            )}
          />
        </Box>
        <Box mb={1}>
          <Paper variant="outlined">
            <Box pl={2}>
              <Typography variant="overline">Photos</Typography>
            </Box>
            <Box pl={2} pr={2}>
              <Controller
                name="imagesNames"
                control={control}
                render={({ field }) => (
                  <UploadAssets
                    imagesNames={field.value}
                    onSetImagesNames={field.onChange}
                  />
                )}
              />
            </Box>
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          color="primary"
          onClick={handleArticleSubmit(createOrErditArticle)}
        >
          {props.isNew ? 'Create' : 'Update'}
        </Button>
        <Button variant="outlined" onClick={props.onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
