import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { OptionsObject, useSnackbar, ProviderContext, SnackbarKey, VariantType } from 'notistack';
import { useMemo } from 'react';

type TSnackbarCloseButtonProps = {
  onClick: () => void;
};

const SnackbarCloseButton: React.FC<TSnackbarCloseButtonProps> = ({ onClick }) => (
  <IconButton sx={{ color: (t) => t.palette.primary.contrastText }} onClick={onClick}>
    <CloseIcon />
  </IconButton>
);

type TEnqueue = ProviderContext['enqueueSnackbar'];
type TNotification = {
  enqueueSnackbar: TEnqueue;
  closeSnackbar: (key: SnackbarKey) => void;
};
type TOptionsWithOverriddenProps = Omit<OptionsObject, 'variant' | 'action'>;

const action =
  ({ closeSnackbar }: Pick<TNotification, 'closeSnackbar'>) =>
    (key: SnackbarKey) =>
      (
        <SnackbarCloseButton
          onClick={() => {
            closeSnackbar(key);
          }}
        />
      );

const createNotification =
  (variant?: VariantType) =>
    ({ enqueueSnackbar, closeSnackbar }: TNotification) =>
      (message: string, optionsWithOverriddenProps?: TOptionsWithOverriddenProps) => {
        const options: OptionsObject = {
          ...optionsWithOverriddenProps,
          variant: variant,
          action: action({ closeSnackbar }),
        };

        enqueueSnackbar(message, options);
      };

const showErrorNotification = createNotification('error');
const showSuccessNotification = createNotification('success');
const showWarningNotification = createNotification('warning');

export const useNotifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return useMemo(
    () => ({
      showErrorNotification: showErrorNotification({ enqueueSnackbar, closeSnackbar }),
      showSuccessNotification: showSuccessNotification({ enqueueSnackbar, closeSnackbar }),
      showWarningNotification: showWarningNotification({ enqueueSnackbar, closeSnackbar }),
    }),
    [enqueueSnackbar, closeSnackbar],
  );
};
