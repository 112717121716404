import { types } from 'mobx-state-tree';

const ProductProperty = types.model({
  id: types.string,
  createdAt: types.string,
  updatedAt: types.string,
  name: types.string,
  value: types.string,
  code: types.string,
});

export default ProductProperty;
