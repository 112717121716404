import { TableRow, TableCell } from '@mui/material';
import { observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { MERCHANTS } from 'router/path';
import Merchant from 'stores/models/Merchant';
import { getFormattedDate } from 'utils';

import useStyles from './styles';

interface IMerchantsTableRowProps {
  merchant: Instance<typeof Merchant>;
}

const MerchantsTableRow: FC<IMerchantsTableRowProps> = ({ merchant: { id, createdAt, updatedAt, name, description } }) => {
  const history = useHistory();
  const styles = useStyles();

  const openMerchant = () => {
    history.push(`${MERCHANTS}/${id}`);
  };

  return (
    <TableRow key={id} className={styles.row} onClick={openMerchant}>
      <TableCell>{id}</TableCell>
      <TableCell>{getFormattedDate(createdAt)}</TableCell>
      <TableCell>{getFormattedDate(updatedAt)}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{description}</TableCell>
    </TableRow>
  );
};

export default observer(MerchantsTableRow);
