import { useQuery } from '@tanstack/react-query';

import { EQueries } from 'config/queryClient';
import { useApi } from 'context/ApiContext';

type TArticlesQueryParams = { siteId: string };

export const useArticlesQuery = ({ siteId }: TArticlesQueryParams) => {
  const { articlesApi } = useApi();

  return useQuery([EQueries.ARTICLES], async () => articlesApi.getArticles({ siteId }));
};