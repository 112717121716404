import { Link, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { DASHBOARD, SITES, MERCHANTS, PRODUCT_PROPERTIES } from 'router/path';

const StyledMenu = styled(Grid)(({ theme }) => ({
  textTransform: 'uppercase',
  textAlign: 'center',
  fontSize: 14,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '& a': {
    marginRight: theme.spacing(2),
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
    fontWeight: 'bold',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '&.active': {
      boxShadow: `inset 0 -4px ${theme.palette.secondary.main}`,
    },
  },
}));

const Menu: React.FC = () => (
  <StyledMenu>
    <Link component={NavLink} to={DASHBOARD} underline="none" color="secondary">
        Dashboard
    </Link>
    <Link component={NavLink} to={SITES} underline="none" color="secondary">
        Sites
    </Link>
    <Link component={NavLink} to={MERCHANTS} underline="none" color="secondary">
        Merchants
    </Link>
    <Link component={NavLink} to={PRODUCT_PROPERTIES} underline="none" color="secondary">
        Product Properties
    </Link>
  </StyledMenu>
);

export default Menu;
