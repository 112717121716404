/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { IMiddlewareHandler } from 'mobx-state-tree';

const Loading: IMiddlewareHandler = (call, next) => {
  const { processing } = call.tree;

  switch (call.type) {
    case 'flow_spawn':
      processing.increaseInProgress();
      break;
    case 'flow_return':
    case 'flow_throw':
      processing.decreaseInProgress();
      break;
    default:
  }

  next(call);
};

export default Loading;
