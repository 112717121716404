import { Typography } from '@mui/material';

import PageContainer from 'components/PageContainer';

export default function Dashboard() {
  return (
    <PageContainer>
      <Typography variant="h1">Dashboard</Typography>
      <Typography variant="overline">The most interesting info</Typography>
    </PageContainer>
  );
}
