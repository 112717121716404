import { useMutation } from '@tanstack/react-query';

import { useApi } from 'context/ApiContext';
import { TContentForm } from 'types/Content';

export const useEditContentMutation = (onSuccess?: () => void) => {
  const { contentsApi } = useApi();

  return useMutation(
    async ({
      contentId,
      contentForm,
      siteId,
    }: {
      contentId: string;
      contentForm: TContentForm;
      siteId: string;
    }) => contentsApi.editContent({ contentId, contentForm, siteId }),
    {
      onSuccess,
    },
  );
};
