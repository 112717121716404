import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, Paper, Typography } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react';
import React, { FC, useContext } from 'react';
import { useParams } from 'react-router-dom';

import FormSelect from 'components/MobxForm/Select';
import TextField from 'components/MobxForm/TextField';
import { listOfCurrencies } from 'consts/currency';
import { StoreContext } from 'stores';
import { TProduct } from 'stores/models/Product';
import createForm from 'utils/createForm';

import Photos from './Photos';
import Properties from './Properties';
import fields from './fields';

interface IManageProductDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

interface IManageProductDialogState {
  form: any;

  onEnter: () => void;
  addPropertyField: (name: string) => void;
  removePropertyField: (name: string) => void;
  setImagesNames: (names: string[]) => void;
}

interface IManageProductDialogParams {
  id: string;
  productId: string | undefined;
}

const ManageProductDialog: FC<IManageProductDialogProps> = ({ isOpen, onClose }) => {
  const { product, products, merchants, productProperties } = useContext(StoreContext);
  const state = useLocalObservable<IManageProductDialogState>(() => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    form: createForm({ fields }),

    onEnter: () => {
      if (product.data) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        state.form.set('default', product.data);
        product.data.properties?.forEach((property) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          state.form.$('properties').add({ name: property.name, value: property.id, rules: 'required' });
        });
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      state.form.reset();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
      state.form.each((field: any) => field.resetValidation());
    },
    addPropertyField: (name: string) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      state.form.$('properties').add({ name, rules: 'required' });
    },
    removePropertyField: (name: string) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      state.form.$('properties').del(name);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      state.form.$('properties').set('value', null); // just to trigger form when the last property removed
    },
    setImagesNames: (names) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      state.form.$('imagesNames').set('value', names);
    },
  }));
  const { id, productId } = useParams<IManageProductDialogParams>();

  // eslint-disable-next-line complexity
  const submit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    state.form.onSubmit(event);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (!state.form.isValid) return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const data: TProduct = {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      ...state.form.values(),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      properties: state.form.$('properties').values()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        ? state.form.$('properties').map((field: any) => productProperties.list.find((prop) => prop.id === field.value))
        : null,
      siteId: id,
    };

    const resp = await (product.data && productId ? product.editProduct(id, productId, data) : product.createProduct(id, data));

    if (!resp) return;

    if (!product.data) void products.fetch(id);

    onClose();
  };

  return (
    <Dialog fullWidth open={isOpen} TransitionProps={{ onEnter: state.onEnter }} maxWidth="md" onClose={onClose}>
      <DialogTitle>{`${product.data ? 'Edit' : 'Create'} Product`}</DialogTitle>
      <DialogContent>
        <Box mt={1}>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
          <TextField fullWidth field={state.form.$('name')} />
        </Box>
        <Box>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
          <TextField fullWidth field={state.form.$('vendorCode')} />
        </Box>
        <Box>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
          <TextField fullWidth field={state.form.$('route')} />
        </Box>
        <Box>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
          <TextField fullWidth multiline field={state.form.$('description')} rows={7} />
        </Box>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
        <Properties form={state.form} addPropertyField={state.addPropertyField} removePropertyField={state.removePropertyField} />
        <Box>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
          <TextField fullWidth field={state.form.$('price')} />
        </Box>
        <Box>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
          <TextField fullWidth field={state.form.$('oldPrice')} />
        </Box>
        <Box>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
          <FormSelect fullWidth options={listOfCurrencies} field={state.form.$('currency')} />
        </Box>
        <Box>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
          <TextField fullWidth field={state.form.$('quantity')} />
        </Box>
        <Box mb={1}>
          <Paper variant="outlined">
            <Box pl={2}>
              <Typography variant="overline">Merchant</Typography>
            </Box>
            <Box pl={2} pr={2}>
              <Box>
                <FormSelect
                  fullWidth
                  options={merchants.list.map((prop) => ({ label: prop.name, value: prop.id }))}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                  field={state.form.$('merchantId')}
                />
              </Box>
              <Box>
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
                <TextField fullWidth field={state.form.$('merchantVendorCode')} />
              </Box>
              <Box>
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
                <TextField fullWidth field={state.form.$('merchantPrice')} />
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box mb={1}>
          <Paper variant="outlined">
            <Box pl={2}>
              <Typography variant="overline">Photos</Typography>
            </Box>
            <Box pl={2} pr={2}>
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
              <Photos imagesNames={state.form.$('imagesNames').value} setImagesNames={state.setImagesNames} />
            </Box>
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="contained" color="primary" onClick={async (event) => submit(event)}>
          {product.data ? 'Update' : 'Create'}
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(ManageProductDialog);
