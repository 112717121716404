import { Typography, TableContainer, Paper, Table, TableBody, Toolbar, Button } from '@mui/material';
import { observer, useLocalObservable } from 'mobx-react';
import React, { useEffect, useContext, FC } from 'react';

import PageContainer from 'components/PageContainer';
import { StoreContext } from 'stores';

import CreateProductPropertyDialog from './components/CreateProductPropertyDialog';
import ProductPropertiesTableHead from './components/ProductPropertiesTableHead';
import ProductPropertiesTableRow from './components/ProductPropertiesTableRow';
import useStyles from './styles';

interface IProductPropertiesState {
  isCreateDialogOpen: boolean;

  openCreateDialog: () => void;
  closeCreateDialog: () => void;
}

const ProductProperties: FC = () => {
  const { productProperties } = useContext(StoreContext);
  const state = useLocalObservable<IProductPropertiesState>(() => ({
    isCreateDialogOpen: false,

    openCreateDialog: () => {
      state.isCreateDialogOpen = true;
    },
    closeCreateDialog: () => {
      state.isCreateDialogOpen = false;
    },
  }));
  const styles = useStyles();

  useEffect(() => {
    const fetch = () => {
      void productProperties.fetch();
    };

    fetch();

    return () => { productProperties.clear(); };
  }, [productProperties]);

  return (
    <PageContainer>
      <Typography variant="h1">Product Properties</Typography>
      <Typography variant="overline">List of Product Properties</Typography>
      <Paper elevation={8} className={styles.paper}>
        <Toolbar variant="dense" className={styles.toolbar}>
          <Button variant="contained" color="primary" onClick={state.openCreateDialog}>
            Add new product property
          </Button>
        </Toolbar>
        <TableContainer>
          <Table>
            <ProductPropertiesTableHead />
            <TableBody>
              {productProperties.list.map((productProperty) => (
                <ProductPropertiesTableRow {...{ key: productProperty.id, productProperty }} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <CreateProductPropertyDialog isOpen={state.isCreateDialogOpen} onClose={state.closeCreateDialog} />
    </PageContainer>
  );
};

export default observer(ProductProperties);
