import { types, flow, getParent, toGenerator, Instance, applySnapshot } from 'mobx-state-tree';

import { api } from 'api';
import * as urls from 'api/urls';
import { TRootStore } from 'types/store';
import { hasStringMessage } from 'utils/hasStringMessage';

import Merchant from './models/Merchant';

type TMerchants = Instance<typeof Merchant>[];

const Merchants = types
  .model({
    list: types.array(Merchant),
  })
  .actions((self) => ({
    fetch: flow(function* fetch() {
      try {
        const resp = yield* toGenerator(
          api.request<null, TMerchants>({ url: `${urls.MAIN_SERVICE}${urls.MERCHANTS}` }),
        );

        self.list.replace(resp.data.body);

        return resp;
      } catch (error: unknown) {
        const { processing } = getParent<TRootStore>(self);

        if (hasStringMessage(error)) {
          processing.setError(error.message);
        }

        return null;
      }
    }),
    createMerchant: flow(function* createMerchant(data: Instance<typeof Merchant>) {
      try {
        const resp = yield* toGenerator(
          api.request<Instance<typeof Merchant>, Instance<typeof Merchant>>({
            url: `${urls.MAIN_SERVICE}${urls.MERCHANTS}`,
            method: 'post',
            data,
          }),
        );

        return resp;
      } catch (error: unknown) {
        const { processing } = getParent<TRootStore>(self);

        if (hasStringMessage(error)) {
          processing.setError(error.message);
        }

        return null;
      }
    }),
    clear() {
      applySnapshot(self, {});
    },
  }));

export default Merchants;
