import { types, addMiddleware, Instance } from 'mobx-state-tree';
import React from 'react';

import Merchant from 'stores/Merchant';
import Merchants from 'stores/Merchants';
import Processing from 'stores/Processing';
import Product from 'stores/Product';
import ProductProperties from 'stores/ProductProperties';
import ProductProperty from 'stores/ProductProperty';
import Products from 'stores/Products';
import Site from 'stores/Site';
import Sites from 'stores/Sites';

import HandleUnauthorized from './middlewares/HandleUnauthorized';
import Loading from './middlewares/Loading';

export const StoreModel = types
  .model({
    processing: Processing,
    sites: Sites,
    site: Site,
    merchants: Merchants,
    merchant: Merchant,
    productProperties: ProductProperties,
    productProperty: ProductProperty,
    products: Products,
    product: Product,
  })
  .create({
    processing: {},
    sites: {},
    site: {},
    merchants: {},
    merchant: {},
    productProperties: {},
    productProperty: {},
    products: {},
    product: {},
  });

addMiddleware(StoreModel, HandleUnauthorized);
addMiddleware(StoreModel, Loading);

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const StoreContext = React.createContext({} as Instance<typeof StoreModel>);
