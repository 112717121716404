import { useMutation } from '@tanstack/react-query';

import { useApi } from 'context/ApiContext';
import { TArticleForm } from 'types/Article';

export const useEditArticleMutation = (onSuccess?: () => void) => {
  const { articlesApi } = useApi();

  return useMutation(
    async ({
      articleId,
      articleForm,
      siteId,
    }: {
      articleId: string;
      articleForm: TArticleForm;
      siteId: string;
    }) => articlesApi.editArticle({ articleId, articleForm, siteId }),
    {
      onSuccess,
    },
  );
};
